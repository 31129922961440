import './Service.css';
import React, { useState, useEffect } from "react";
import bouleWebp from '../../Asset/webp/boule.webp';
import boulePng from '../../Asset/png/boule.png';
import camionWebp from '../../Asset/webp/camion.webp';
import camionPng from '../../Asset/png/camion.png';
import horlogeWebp from '../../Asset/webp/horloge.webp';
import horlogePng from '../../Asset/png/horloge.png';
import outilsWebp from '../../Asset/webp/outils.webp';
import outilsPng from '../../Asset/png/outils.png';
import bouclierWebp from '../../Asset/webp/bouclier.webp';
import bouclierPng from '../../Asset/png/bouclier.png';
import pop from '../../Asset/svg/pop.svg';
import Card from '../../Components/Card/Card';
import Recap from '../../Components/Recap/Recap';
import Modal from '../../Components/Modal/Modal';
import DataService from '../../Data/dataService';
import { useSelector, useDispatch } from 'react-redux';
import {setStep } from '../../Redux/slice';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Service = () => {

    localStorage.setItem('step', 'service');
    
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const token = useSelector((state) => state.token.value);
    
    const [price, setPrice] = useState(0);

    const [isLoading, setIsLoading] = useState(true);

    const [response, setresponse] = useState({
        Insurance: ['14.99', '29.99'],
        Price: "1400",
        Review: "9.99",
        Support: "9.99",
        VehicleType: "Trottinette",
        Warranty:['9.99', '14.99']},
    );

    const  [servicesForm, setservicesForm] = useState( {
        Insurance:0,
        Warranty:0,
        Maintenance:0,
        Assistance:0,
    });

    const  [servicesData, setservicesData] = useState( [
        {
            serviceName: "Insurance",
            serviceLegend: [],
            serviceOption: ["Standard", "Premium"],
            servicePop:  [],
            serviceContent:  [],
            serviceContract: "Assurance",
            onlyMoto: "Only Moto",
            serviceContractValue: [9.99, 15.99],
            serviceImage: [bouclierWebp,bouclierPng],
            InsuranceAuthor: false,
        },
        {
            serviceName: "Warranty",
            serviceLegend: [],
            serviceOption: ["Standard", "Premium"],
            servicePop: [],
            serviceContent: [],
            serviceContract: "Garantie",
            onlyMoto: "",
            serviceContractValue: [9.99, 15.99],
            serviceImage: [horlogeWebp,horlogePng],
        },
        {
            serviceName: "Maintenance",
            serviceLegend: [],
            serviceOption: false,
            servicePop: [],
            serviceContent: [],
            serviceContract: "Revision",
            onlyMoto: "",
            serviceContractValue: [9.99],
            serviceImage: [camionWebp,camionPng],
        },
        {
            serviceName: "Assistance",
            serviceLegend: [],
            serviceOption: false,
            servicePop: [],
            serviceContent: [],
            serviceContract: "Assistance",
            onlyMoto: "",
            serviceContractValue: [9.99],
            serviceImage: [outilsWebp,outilsPng],
        }
    ]);

    const [servicesOption, setServicesOption] = useState([
        {
            serviceName: 'Insurance',
            checked: false,
            selectedOption: 0,
            showPopup: false,
        },
        {
            serviceName: 'Warranty',
            checked: false,
            selectedOption: 0,
            showPopup: false,
        },
        {
            serviceName: 'Maintenance',
            checked: false,
            selectedOption: 0,
            showPopup: false,
        },
        {
            serviceName: 'Assistance',
            checked: false,
            selectedOption: 0,
            showPopup: false,
        }
    ]);

    const [showPopup, setShowPopup] = useState({
        final: false,
    });

    const toggleFinalPopup = () => {
        setShowPopup(prevState => ({
            ...prevState,
            final: !prevState.final
        }));
    };

    const toggleServicePopup = (serviceName) => {
        setServicesOption(prevState => {
            const updatedData = prevState.map(service => {
                if (service.serviceName === serviceName) {
                return {
                    ...service,
                    showPopup: !service.showPopup
                };
                }
                return service;
            });
            return updatedData;
        });
    };

    const toggleCheckbox = (index) => {
        let newServicesOption = [...servicesOption];
        if (newServicesOption[index]) {
            newServicesOption[index].checked = !newServicesOption[index].checked;
            setServicesOption(newServicesOption);

            setservicesForm(prevServicesForm => ({
                ...prevServicesForm,
                Insurance: servicesOption[0].checked ? +servicesOption[0].selectedOption + 1 : 0,
                Warranty: servicesOption[1].checked ? +servicesOption[1].selectedOption + 1 : 0,
                Maintenance: servicesOption[2].checked ? +servicesOption[2].selectedOption + 1 : 0,
                Assistance: servicesOption[3].checked ? +servicesOption[3].selectedOption + 1 : 0,
            }));
    
        } else {
            console.error('Index out of range');
        }
    }

    const handleOptionChange = (event, index) => {
        let newServicesOption = [...servicesOption];
        newServicesOption[index].selectedOption = event.target.value; // Use index to target the correct service option
        setServicesOption(newServicesOption);
    
        setservicesForm(prevServicesForm => ({
            ...prevServicesForm,
            Insurance: newServicesOption[0].checked ? +newServicesOption[0].selectedOption + 1 : 0,
            Warranty: newServicesOption[1].checked ? +newServicesOption[1].selectedOption + 1 : 0,
            Maintenance: newServicesOption[2].checked ? +newServicesOption[2].selectedOption + 1 : 0,
            Assistance: newServicesOption[3].checked ? +newServicesOption[3].selectedOption + 1 : 0,
        }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault(); // Empêche le rechargement de la page lors de la soumission du formulaire

        try {

            setIsLoading(true);

            const response = await axios.patch(
                `https://server.sikomobility.com/api/floa/service`,
                {
                    InsuranceContract: servicesForm.Insurance,
                    WarrantyContract: servicesForm.Warranty,
                    ReviewContract: servicesForm.Maintenance,
                    SupportContract: servicesForm.Assistance,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response.data.message === 'Service updated successfully.') {
                if (response.data.MonthlyPayment === "CNX" || servicesForm.Insurance !==0 || servicesForm.Warranty !==0 || servicesForm.Maintenance !==0 || servicesForm.Assistance !==0) {
                    dispatch(setStep('pay')); // Mise à jour du step
                    localStorage.setItem('step', 'pay');
                    navigate('/Pay');
                    window.location.reload();
                }else{
                    try {
                        const response = await axios.get('https://server.sikomobility.com/api/floa/univers', {
                            headers: {
                                Authorization: `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            }
                        });
                        // console.log(response);
                        localStorage.setItem('step', 'final');
                        dispatch(setStep('final')); // Mise à jour du step
                        window.location.assign(response.data.PaymentUrl);
                    } catch (error) {
                        console.error("Erreur lors de la requête :", error); // Utilisez console.error pour afficher les erreurs
                    
                        if (error.status === 500) {
                            localStorage.setItem('step', 'final');
                            dispatch(setStep('final')); // Mise à jour du step
                            navigate('/Final');
                        }
                        
                        localStorage.setItem('step', 'final');
                        dispatch(setStep('final')); // Mise à jour du step
                        navigate('/Final');
                    }
                }
            } 

        } catch (error) {
            // console.log(error);
        }finally {
            setIsLoading(false); // Fin du chargement
        }
    };

    const totalChecked = servicesOption.map(service => +service.checked).reduce((a, b) => a + b, 0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true); // Début du chargement
                const response = await axios.get('https://server.sikomobility.com/api/floa/price', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                });
                setresponse(response.data);
                console.log(response.data);
            
                setservicesData(prevServicesData => prevServicesData.map(service => {
                    if(service.serviceName === 'Insurance') {
                        return {...service, serviceContractValue: response.data.Insurance};
                    }
                    if(service.serviceName === 'Warranty') {
                        return {...service, serviceContractValue: response.data.Warranty};
                    }
                    if(service.serviceName === 'Maintenance') {
                        return {...service, serviceContractValue: [response.data.Review]};
                    }
                    if(service.serviceName === 'Assistance') {
                        return {...service, serviceContractValue: [response.data.Support]};
                    }
                    return service;
                }));
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false); // Fin du chargement
            }
        };

        fetchData();
    }, [token]);

    useEffect(() => {
        const newServicesData = DataService(
            response.Price,
            response.VehicleType,
            servicesOption,
            servicesData
        );
    
        // Calcul du prix total
        const total = servicesOption.reduce((accumulateur, serviceOption) => {
            if (serviceOption.checked) {
                // Trouver les données de service correspondant à l'option sélectionnée
                const serviceData = servicesData.find(data => data.serviceName === serviceOption.serviceName);
                
                // Si le type de véhicule est une Moto ou un Scooter et que le service est l'Assurance, ne pas ajouter le prix
                if ((response.VehicleType === 'Moto' || response.VehicleType === 'Scooter') && serviceData.serviceName === "Insurance") {
                    return accumulateur; // Retourne l'accumulateur sans changer la valeur
                }
                
                // Ajouter la valeur du contrat de service à l'accumulateur
                return (
                    accumulateur +
                    Number(serviceData.serviceContractValue[serviceOption.selectedOption])
                );
            }
            return accumulateur; // Si l'option n'est pas cochée, retourner l'accumulateur sans changement
        }, 0);

        // Mettre à jour le prix avec la valeur totale calculée
        setPrice(total.toFixed(2));

    
        if (JSON.stringify(newServicesData) !== JSON.stringify(servicesData)) {
            setservicesData(newServicesData);
        }
    }, [servicesOption, response, isLoading]);

    return (
        <form >
            {isLoading ? (
                <>
                    <div className="loader_all">
                        <div className="loader">
                            <div className="box box0">
                                <div></div>
                            </div>
                            <div className="box box1">
                                <div></div>
                            </div>
                            <div className="box box2">
                                <div></div>
                            </div>
                            <div className="box box3">
                                <div></div>
                            </div>
                            <div className="box box4">
                                <div></div>
                            </div>
                            <div className="box box5">
                                <div></div>
                            </div>
                            <div className="box box6">
                                <div></div>
                            </div>
                            <div className="box box7">
                                <div></div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>

                    <div className="Content">
            
                        <div className="resultat_title">
                            <h1> NOS SERVICES SANS ENGAGEMENT </h1>
                        </div>

                        <picture id="b1" className="boule">
                            <source srcSet={bouleWebp} type="image/webp" />
                            <source srcSet={boulePng} type="image/png" />
                            <img src={bouleWebp} alt="boule" />
                        </picture>

                        <picture id="b2" className="boule">
                            <source srcSet={bouleWebp} type="image/webp" />
                            <source srcSet={boulePng}  type="image/png" />
                            <img src={bouleWebp} alt="boule" />
                        </picture>
                        
                        <div className="service">
                            
                        {Array.isArray(servicesData) && Array.isArray(servicesOption) && servicesData.map((service, index) => (
                            <Card
                                key={index}
                                serviceOption={servicesOption[index]}
                                serviceData={service}
                                index={index}
                                toggleServicePopup={toggleServicePopup}
                                handleOptionChange={handleOptionChange}
                                pop={pop}
                                toggleCheckbox={toggleCheckbox}
                                VehicleType={response.VehicleType}
                            />
                        ))}
                                                
                        </div>
                    </div>

                    <div className="send">

                        <div className="recap_service">

                            <div>
                                <h4> TOTAL SERVICE : </h4>
                                {response.VehicleType === 'Moto' || response.VehicleType === "Scooter" ? (
                                    <p>(Hors Asurrance)</p>
                                ) : null}
                                
                            </div>

                            {
                                servicesData.map((service, index) => (
                                    <div
                                        key={index}
                                        id={`recap${service.serviceName}`}
                                        className={servicesOption[index].checked ? "recapDiv" : "recap_close"}
                                    >
                                        <picture>
                                            <source srcSet={service.serviceImage[0]} type="image/webp" />
                                            <source srcSet={service.serviceImage[1]} type="image/png" />
                                            <img src={service.serviceImage[0]} alt={service.serviceName} />
                                        </picture>
                                    </div>
                                ))
                            }
                        </div>

                        <div className="recap_price">
                            <div>
                                <h4>{price}€ / mois</h4>
                            </div>

                            <p className="button" id="open_pop" onClick={toggleFinalPopup}>Suivant</p>

                        </div>
                    </div>

                    <Modal className={`popOverlay ${showPopup.final ? 'pop_open' : 'pop_close'}`}>
                        <div>
                            <h1>Confirmer les Services</h1>
                        </div>
                        <div className="pop_content">
                            {totalChecked === 0 ? (
                                <div className="noService">
                                    <h4>Etes vous sûrs? <br></br>66% de nos clients prennent au moins un service pour rouler serein.</h4>
                                </div>
                            ) : (
                                <div className="pop_content_flex" id="yesService">
                                    {servicesOption.map(serviceOption => {
                                        const serviceData = servicesData.find(data => data.serviceName === serviceOption.serviceName);

                                        return (
                                            <Recap
                                                key={serviceOption.serviceName}
                                                serviceOption={serviceOption}
                                                serviceData={serviceData}
                                            />
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                        <div className="buttonDiv">
                            <p className="buttonTall" id="close_pop" onClick={toggleFinalPopup}> {totalChecked === 0 ?( <>Je prends un service</> ):(<>Retour</>)}</p>
                            <button className="buttonTall" type="submit" onClick={handleSubmit}>{totalChecked === 0 ?( <>Je ne veux aucun service</> ):(<>Confirmer</>)}</button>
                        </div>
                        <br></br>
                        <div className="legalconditions">
                            {totalChecked === 0 ? (
                                <>
                                    <small>
                                        En cliquant sur "Je ne veux aucun service" vous acceptez que vos données personnelles soient transmises et traitées par FLOA dans le cadre de votre demande de paiement en plusieurs fois.
                                        Plus d'informations <a href="https://www.floabank.fr/politique-confidentialite" target="_blank" rel="noreferrer" style={{ borderBottom: '2px solid green', fontSize: 'x-small'}}> ici.</a>
                                    </small>
                                    <br />
                                    <br />
                                    <small>
                                        En cliquant sur "Je ne veux aucun service" vous avez pris connaissance et acceptez les conditions générales du paiement en plusieurs fois de FLOA
                                        Voir <a href="https://www.floabank.fr/conditions-generales-paiement-plusieurs-fois" target="_blank" rel="noreferrer" style={{ borderBottom: '2px solid green', fontSize: 'x-small'}}> ici.</a>
                                    </small>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </Modal>
                </>
            )}

        </form>
    );
};
  
export default Service;