import './Home.css';
import { useParams } from 'react-router-dom';
import { useState,useEffect,useMemo } from 'react';
import bouleWebp from '../../Asset/webp/boule.webp';
import boulePng from '../../Asset/png/boule.png';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { sendToken, setStep } from '../../Redux/slice';
import Notif from '../../Components/Notif/Notif'
import { LoadScript, Autocomplete } from '@react-google-maps/api';
import React from 'react';
import Modal from '../../Components/Modal/Modal';
import { useSelector, useDispatch } from 'react-redux';

const libraries = ['places'];

function Home() {

    localStorage.setItem('step', 'home');

    const isLoggedIn = useSelector((state) => state.token.value !== null);
    const token = useSelector((state) => state.token.value);

    const today = new Date();
    const minYear = today.getFullYear() - 100;
    const maxYear = today.getFullYear() - 10;

    const formatWithLeadingZero = (value) => {
    return value.toString().padStart(2, '0');
    };

    const minDateString = `${minYear}-${formatWithLeadingZero(today.getMonth() + 1)}-${formatWithLeadingZero(today.getDate())}`;
    const maxDateString = `${maxYear}-${formatWithLeadingZero(today.getMonth() + 1)}-${formatWithLeadingZero(today.getDate())}`;

    const dispatch = useDispatch(); 
    const navigate = useNavigate();

    const [notification, setNotification] = useState(null);

    const { firstName, lastName, birthCity, birthDate, birthZipCode, address, zipCode, city, phoneNumber, email, vehicleType, brand, model, amount, monthlyPayment , merchant , merchantEmail} = useParams();

    const [autocomplete, setAutocomplete] = React.useState(null);

    const [autocompleteShipping, setAutocompleteShipping] = React.useState(null);

    const [Shipping, setShipping] = useState(true);

    const [modal, setModal] = useState(false);

    useEffect(() => {
        if (isLoggedIn) {
            setModal(true);
        }else{
            setModal(false);
        }
    }, [isLoggedIn]);

    const [formValues, setFormValues] = useState({
        Civility : null,
        FirstName: firstName || null,
        LastName: lastName || null,
        MaidenName : null,
        BirthCity: birthCity || null,
        BirthDate: birthDate || null,
        BirthZipCode: birthZipCode || null,
        Address: address || null,
        ZipCode: zipCode || null,
        City: city || null,
        PhoneNumber: phoneNumber || null,
        Email: email || null,
        VehicleType: vehicleType || 'Moto',
        Brand: brand || "Autre",
        Model: model || null,
        Amount: amount || null,
        MonthlyPayment: monthlyPayment || 3,
        ShippingAddress: null,
        ShippingZipCode: null,
        ShippingCity: null,
        Merchant : merchant || null,
        MerchantEmail : merchantEmail || null,
    });


    const onLoad = (autocomplete) => {
        setAutocomplete(autocomplete);
    };
    
    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            let place = autocomplete.getPlace();
            let address = '', // Initialise l'adresse
                city = '',
                zip = '';
    
            if (place.address_components[0] && place.address_components[1]) {
                // Fusionner les deux premiers éléments pour obtenir l'adresse
                address = `${place.address_components[0].long_name} ${place.address_components[1].long_name}`;
            }
    
            // Parcourir les composants d'adresse pour extraire la ville et le code postal
            for (let i = 0; i < place.address_components.length; i++) {
                let addr = place.address_components[i];
                if (addr.types[0] === 'locality') city = addr.long_name;
                if (addr.types[0] === 'postal_code') zip = addr.long_name;
            }
    
            // Mettre à jour formValues avec les nouvelles informations
            setFormValues({
                ...formValues,
                Address: address,
                City: city,
                ZipCode: zip,
            });
        }
    };

    const onLoadShipping = (autocomplete) => {
        setAutocompleteShipping(autocomplete);
    };

    const onShippingPlaceChanged = () => {
        if (autocompleteShipping !== null) {
            let place = autocompleteShipping.getPlace();
            let shippingAddress = '', 
                shippingCity = '',
                shippingZip = '';
            
            if (place.address_components[0] && place.address_components[1]) {
                shippingAddress = `${place.address_components[0].long_name} ${place.address_components[1].long_name}`;
            }
        
            for (let i = 0; i < place.address_components.length; i++) {
                let addr = place.address_components[i];
                if (addr.types[0] === 'locality') shippingCity = addr.long_name;
                if (addr.types[0] === 'postal_code') shippingZip = addr.long_name;
            }
        
            setFormValues({
                ...formValues,
                ShippingAddress: shippingAddress,
                ShippingCity: shippingCity,
                ShippingZipCode: shippingZip,
            });
        }
    };

    const handleShipping = (e) => {
        setShipping(!Shipping)
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
      
        if (name === 'PhoneNumber') {
          // Supprimer les caractères non numériques
          const numericValue = value.replace(/\D/g, '');
      
          // Limiter la longueur du numéro de téléphone à 10 chiffres
          const truncatedValue = numericValue.slice(0, 10);
      
          // Mettre à jour la valeur dans le state ou le contexte
          setFormValues((prevValues) => ({
            ...prevValues,
            [name]: truncatedValue,
          }));
        } else if (name === 'ShippingZipCode' || name === 'BirthZipCode') {
          // Limiter la longueur du code postal à 5 caractères
          const truncatedValue = value.slice(0, 5);
      
          // Mettre à jour la valeur dans le state ou le contexte
          setFormValues((prevValues) => ({
            ...prevValues,
            [name]: truncatedValue,
          }));
        } else {
          // Mettre à jour les autres champs du formulaire
          setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
          }));
        }
      
        if (Shipping) {
          setFormValues((prevValues) => ({
            ...prevValues,
            ShippingAddress: prevValues.Address,
            ShippingZipCode: prevValues.ZipCode,
            ShippingCity: prevValues.City,
          }));
        }
      };

    const [priceValues, setpriceValues] = useState({
        first_Amount:0,
        last_Amount:0
    })

    const handlePriceChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => {
            const newValues = {...prevValues, [name]: (name === 'Amount' || (name === 'MonthlyPayment' && value !=="CNX") ) ? parseFloat(value) : value};
            let marge;
            let marge_f;
            let tempPrice = 0;

            if (newValues.Amount <= 1000) {
                marge = 1.12;
            } else if(newValues.Amount <= 2000) {
                marge = 1.11;
            } else if(newValues.Amount <= 3250) {
                marge = 1.10;
            } else if(newValues.Amount <= 4500) {
                marge = 1.09;
            }else if(newValues.Amount <= 5000) {
                marge = 1.08;
            } else if(newValues.Amount <= 5500) {
                marge = 1.07;
            } else {
                marge = 0;
            }

            tempPrice = newValues.Amount * marge;
    
            if(newValues.MonthlyPayment === 3) {
                if (newValues.Amount >= 5885){
            
                    marge_f = 0 ;
                
                }else if(newValues.Amount >= 3000){
                    
                    marge_f =1.008 ;
                
                }else if(newValues.Amount >= 2000){
                    
                    marge_f = 1.01259 ;
                
                }else if(newValues.Amount >= 1750){
                    
                    marge_f = 1.0146 ;
                
                }else if(newValues.Amount >= 1000){
                    
                    marge_f = 1.015 ;
                
                }else{
                    
                    marge_f =1.0153   ;
                
                }
            } else if(newValues.MonthlyPayment === 4) {
                if (newValues.Amount >= 5885){
            
                    marge_f = 0 ;
                }
                else if(newValues.Amount >= 2250){
                    
                    marge_f =1.019 ;
                }
                else if(newValues.Amount >= 1750){
                    
                    marge_f = 1.022 ;
                }
                else{
                    
                    marge_f =1.023   ;
                
                }
        
            } else if(newValues.MonthlyPayment === 10) {
                marge_f = newValues.Amount >= 3000 ? 0 : 1.05;
            }
    
            tempPrice *= marge_f;
    
            const modulo = (tempPrice * 100 % newValues.MonthlyPayment) / 100;
            let first_Amount = (tempPrice / newValues.MonthlyPayment) + modulo ;
            first_Amount = Math.round(first_Amount * 100) / 100;
            
            let last_Amount = tempPrice / newValues.MonthlyPayment;
            last_Amount = Math.round(last_Amount * 100) / 100;
    
            setpriceValues((prevValues) => ({
                ...prevValues,
                first_Amount: first_Amount,
                last_Amount: last_Amount,
            }));
    
            return newValues;
        });
    };

    const closeNotification = () => {
        setNotification(null);
    };

    const handleSubmit = async (e) => {

        console.log(formValues);

        let brandValue = formValues.Brand;
        if (formValues.Brand === "Autre") {
            brandValue = formValues.OtherBrand;
        }

        e.preventDefault(); // Empêche le rechargement de la page lors de la soumission du formulaire
    
        // Vérifie si l'une des valeurs spécifiques de formValues est vide
        const fieldsToCheck = ['Address', 'City', 'ZipCode'];

        for (const key of fieldsToCheck) {
            if (formValues[key] === null) {
                setNotification({ type: 'error', message: `Vérifiez le champ 'Adresse', Vous devez selectionner une adresse dans le menu déroulant.` });
                return; // Sort de la fonction si une valeur est vide
            }
        }
    
        let additionalData = {};
        if (formValues.MonthlyPayment === 12 || formValues.MonthlyPayment === 24 || formValues.MonthlyPayment === 36) {
            additionalData = { MonthlyPayment: "CNX" };
        } else {
            additionalData = { MonthlyPayment: formValues.MonthlyPayment};
        }
        
        if (Shipping) {
            try {
                const response = await axios.post(`https://server.sikomobility.com/api/floa/index`,{
                    Civility : formValues.Civility,
                    FirstName:  formValues.FirstName,
                    LastName: formValues.LastName,
                    MaidenName : formValues.MaidenName,
                    BirthCity: formValues.BirthCity,
                    BirthDate: formValues.BirthDate,
                    BirthZipCode: formValues.BirthZipCode,
                    Address: formValues.Address,
                    ZipCode: formValues.ZipCode,
                    City: formValues.City,
                    PhoneNumber: formValues.PhoneNumber,
                    Email: formValues.Email,
                    VehicleType: formValues.VehicleType,
                    Brand: brandValue,
                    Model: formValues.Model,
                    Amount: formValues.Amount,
                    ...additionalData,
                    ShippingAddress: formValues.Address,
                    ShippingZipCode: formValues.ZipCode,
                    ShippingCity: formValues.City,
                    Merchant : formValues.Merchant,
                    MerchantEmail : formValues.MerchantEmail,
                });
                
                localStorage.setItem('token', response.data.id);
                localStorage.setItem('step', 'service');
                dispatch(sendToken(response.data.id));
                dispatch(setStep('service')); // Mise à jour du step
                navigate('/Service');
                // console.log({ type: 'success', message: response.data.message });
            } catch (error) {
                // console.log({ type: 'error', message: error.message });
            }
        } else {
            try {
                const response = await axios.post(`https://server.sikomobility.com/api/floa/index`,{
                    Civility : formValues.Civility,
                    FirstName:  formValues.FirstName,
                    LastName: formValues.LastName,
                    MaidenName : formValues.MaidenName,
                    BirthCity: formValues.BirthCity,
                    BirthDate: formValues.BirthDate,
                    BirthZipCode: formValues.BirthZipCode,
                    Address: formValues.Address,
                    ZipCode: formValues.ZipCode,
                    City: formValues.City,
                    PhoneNumber: formValues.PhoneNumber,
                    Email: formValues.Email,
                    VehicleType: formValues.VehicleType,
                    Brand: brandValue,
                    Model: formValues.Model,
                    Amount: formValues.Amount,
                    ...additionalData,
                    ShippingAddress: formValues.ShippingAddress,
                    ShippingZipCode: formValues.ShippingZipCode,
                    ShippingCity: formValues.ShippingCity,
                    Merchant : formValues.Merchant,
                    MerchantEmail : formValues.MerchantEmail,
                });
                localStorage.setItem('token', response.data.id);
                localStorage.setItem('step', 'service');
                dispatch(sendToken(response.data.id));
                dispatch(setStep('service'));
                navigate('/Service');
                // console.log({ type: 'success', message: response.data.message });
            } catch (error) {
                // console.log({ type: 'error', message: error.message });
            }
        }
    };

    const [brands, setBrands] = useState([]);
    const [optionAutre, setOptionAutre] = useState(null);

    useEffect(() => {
        const fetchBrands = async () => {
            try {
                const response = await axios.get(`https://server.sikomobility.com/api/vehicle/catalogue`);
                setBrands(response.data);
        
                const optionAutre = {
                    value: 'Autre',
                    text: 'Autre',
                };
        
                setOptionAutre(optionAutre);
        
            } catch (error) {
                console.error(error);
            }
        };
        
        fetchBrands();
    }, []);

    const SameClient = async () => {
        // Fermeture de la fenêtre modale
        setModal(false);
        
        // Appel API pour récupérer les informations
        try {
            const response = await axios.get('https://server.sikomobility.com/api/floa/index', {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            
            // Mise à jour des valeurs du formulaire avec la réponse de l'API
            setFormValues({
                ...formValues,
                ...response.data
            });
        } catch (error) {
            console.error(error);
        }
    };

    const NewClient = () => {
        // Fermeture de la fenêtre modale
        setModal(false);
    
        // Suppression du token dans le local storage
        localStorage.removeItem('token');
    };

    const filteredBrands = useMemo(() => {
        return brands.filter((brand) => brand.VehicleType === formValues.VehicleType);
    }, [formValues.VehicleType, brands])

    return (
        <>
            <Notif
                type={notification && notification.type}
                message={notification && notification.message}
                onClick={closeNotification}
            />

            {isLoggedIn && modal && (
                <Modal className="popOverlay">
                    <h4>Souhaitez-vous reprendre vos réponses précédentes ?</h4>
                    <br />
                    <div className="buttonDiv">
                        <p className="buttonTall" onClick={NewClient}>
                            Non
                        </p>
                        <p className="buttonTall" onClick={SameClient}>
                            Oui
                        </p>
                    </div>
                </Modal>
            )}

            
            <LoadScript googleMapsApiKey="AIzaSyDsZ0rldhrOp0D3G6dlxbCi3Kwoojwo-88" libraries={libraries}>

            <form onSubmit={handleSubmit} >
                <div className="Content form">
                    <h2>IDENTITÉ</h2>
                    <div className="radioDivSup">
                        <div className="radioDiv">
                            <input type="radio" id="Mrs" name="Civility" value="Mrs" required onChange={handleInputChange} />
                            <label htmlFor="Mrs">Madame</label>
                        </div>
                        <div className="radioDiv">
                            <input type="radio" id="Mr" name="Civility" value="Mr" required onChange={handleInputChange} />
                            <label htmlFor="Mr">Monsieur</label>
                        </div>
                    </div>

                    <div className="gridForm">
                        <div className="inputDiv">
                            <input type="text" id="LastName" name="LastName" value={formValues.LastName} onChange={handleInputChange} required />
                            <span></span>
                            <label htmlFor="LastName">Nom</label>
                        </div>
                        <div className="inputDiv">
                            <input type="text" id="FirstName" name="FirstName" value={formValues.FirstName} onChange={handleInputChange} required />
                            <span></span>
                            <label htmlFor="FirstName">Prénom</label>
                        </div>
                        <div className="inputDiv">
                            <input type="text" id="BirthCity" name="BirthCity" value={formValues.BirthCity} onChange={handleInputChange} required />
                            <span></span>
                            <label htmlFor="BirthCity">Ville de naissance</label>
                        </div>
                        <div className="inputDiv">
                            <input type="date" id="BirthDate" name="BirthDate" value={formValues.BirthDate} min={minDateString} max={maxDateString} onChange={handleInputChange} required />
                            <span></span>
                            <label htmlFor="BirthDate">Date de naissance</label>
                        </div>
                        <div className="inputDiv">
                            <input type="numbre" id="BirthZipCode" name="BirthZipCode" value={formValues.BirthZipCode} onChange={handleInputChange} required />
                            <span></span>
                            <label htmlFor="BirthZipCode">Code postal de naissance</label>
                        </div>
                        {formValues.Civility ==="Mrs" && ( 
                            <div className="inputDiv">
                                <input type="text" id="MaidenName" name="MaidenName" value={formValues.MaidenName} onChange={handleInputChange} required />
                                <span></span>
                                <label htmlFor="MaidenName">Nom de naissance</label>
                            </div>
                        )}
                    </div>

                    <h2>COORDONNÉES</h2>
                    <div className="gridForm">
                        <div className="inputDiv">

                                <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                                    <input type="text" name='Address' placeholder='' id='Address' required />
                                </Autocomplete>
                            <span></span>
                            <label htmlFor="Address">Adresse</label>
                        </div>

                        <div className="inputDiv">
                            <input type="tel" id="PhoneNumber" name="PhoneNumber" value={formValues.PhoneNumber} onChange={handleInputChange} required />
                            <span></span>
                            <label htmlFor="PhoneNumber">Numéro de téléphone</label>
                        </div>
                        <div className="inputDiv">
                            <input type="Email" id="Email" name="Email" value={formValues.Email} onChange={handleInputChange} required />
                            <span></span>
                            <label htmlFor="Email">Adresse e-mail</label>
                        </div>

                        <div className="switch_box" id='Shipping'>
                            <input type="checkbox" className="switch" name="Shipping" id="Shipping" checked={Shipping} onChange={handleShipping} />
                            <span>Adresse de livraison identique à cette adresse ?</span>
                        </div>

                        {!Shipping && (
                            <>
                                <div className="inputDiv">
                                        <Autocomplete onLoad={onLoadShipping} onPlaceChanged={onShippingPlaceChanged}>
                                            <input type="text" id="ShippingAddress" name="ShippingAddress" placeholder='' required />
                                        </Autocomplete>
                                    <span></span>
                                    <label htmlFor="ShippingAddress">Adresse de livraison</label>
                                </div>
                            </>
                        )}
                        
                    </div>

                    <h2>VEHICULE</h2>
                    <div className="gridForm">
                        <div className="inputDiv">
                            <select name="VehicleType" id="VehicleType" onChange={handleInputChange} value={formValues.VehicleType}>
                                <option value="Moto">Moto</option>
                                <option value="Scooter">Scooter</option>
                                <option value="Velo">Velo</option>
                                <option value="Trottinette">Trottinette</option>
                                <option value="Cargo">Velo Cargo</option>
                            </select>
                            <span></span>
                            <label htmlFor="VehicleType">Type de véhicule</label>
                        </div>
                        <div className="inputDiv">
                            <select name="Brand" id="Brand" onChange={handleInputChange} value={formValues.Brand}>
                                {optionAutre && (
                                    <option value={optionAutre.value}>{optionAutre.text}</option>
                                )}
                                {(filteredBrands.length > 0 ? filteredBrands : brands).map((brand) => (
                                    <option key={brand.id} value={brand.Brand}>
                                        {brand.Brand}
                                    </option>
                                ))}
                            </select>
                            <span></span>
                            <label htmlFor="Brand">Marque</label>
                        </div>
                        <div className="inputDiv">
                            <input type="text" id="Model" name="Model" value={formValues.Model} onChange={handleInputChange} required />
                            <span></span>
                            <label htmlFor="Model">Modèle</label>
                        </div>
                        {formValues.Brand === "Autre" &&
                            <div className="inputDiv">
                                <input type="text" id="OtherBrand" name="OtherBrand" value={formValues.OtherBrand} onChange={handleInputChange} required={formValues.Brand === "Autre"} />
                                <span></span>
                                <label htmlFor="OtherBrand">Autre marque</label>
                            </div>
                        }
                        
                    </div>

                    <picture id="b1" className="boule">
                        <source srcSet={bouleWebp} type="image/webp" />
                        <source srcSet={boulePng} type="image/png" />
                        <img src={bouleWebp} alt="boule" />
                    </picture>

                    <picture id="b2" className="boule">
                        <source srcSet={bouleWebp} type="image/webp" />
                        <source srcSet={boulePng}  type="image/png" />
                        <img src={bouleWebp} alt="boule" />
                    </picture>

                    <h2>INFORMATIONS FINANCEMENT</h2>
                    <div className="gridForm">

                        <div className="inputDiv">
                            <input type="number" id="Amount" name="Amount" max={formValues.MonthlyPayment === "12" || formValues.MonthlyPayment === "24" || formValues.MonthlyPayment === "36" ? 100000 : (formValues.MonthlyPayment <= 4 ? 5750 : 2720)} min={formValues.MonthlyPayment === 'CNX' ? 0 : (formValues.MonthlyPayment <= 4 ? 250 : 250)} value={formValues.Amount} onChange={handlePriceChange} required />
                            <span></span>
                            <label htmlFor="Amount">Montant à financer </label>
                        </div>
                        
                        <div className="inputDiv">
                            <select id="MonthlyPayment" name="MonthlyPayment" value={formValues.MonthlyPayment} onChange={handlePriceChange}>
                                <option value="3">3 mois</option>
                                <option value="4">4 mois</option>
                                <option value="10">10 mois</option>
                                {/* <option value="12">12 mois</option>
                                <option value="24">24 mois</option>
                                <option value="36">36 mois</option> */}
                            </select>
                            <label htmlFor="MonthlyPayment">Nombre de paiements</label>
                        </div>
                    </div>
                    
                </div>

                <div className="send">

                    
                    <div id="price">
                        {formValues.MonthlyPayment === 12 || formValues.MonthlyPayment === 24 || formValues.MonthlyPayment === 36 ? (
                            <p>Votre échéancier sera automatiquement calculé à l'étape suivante.</p>
                        ) : formValues.MonthlyPayment === 10 ? (
                            <>
                                <p className="limite" id='limite2'>1ère mensualité à régler aujourd’hui : <span id="text_1">{priceValues.first_Amount}</span>€*</p>
                                <p className="limite" id='limite3'>Suivi de <span id="text_2">{priceValues.last_Amount}</span>€ pendant <span id="text_3">{formValues.MonthlyPayment-1}</span> mois</p>
                            </>
                        ) : (
                            <>
                                <p className="limite" id='limite2'>1ère mensualité à régler aujourd’hui : <span id="text_1">{priceValues.first_Amount}</span>€*</p>
                                <p className="limite" id='limite3'>Suivie de <span id="text_2">{priceValues.last_Amount}</span>€ pendant <span id="text_3">{formValues.MonthlyPayment-1}</span> mois*</p>
                            </>
                        )}
                    </div>

                    <button type="submit" className='button'value="Valider">Suivant</button>

                </div>
                <div className='conditionslegales'>
                {formValues.MonthlyPayment === "12" || formValues.MonthlyPayment === "24" || formValues.MonthlyPayment === "36"? (
                    null
                    ):formValues.MonthlyPayment === 10 ? (
                        <p style={{ fontSize: 'x-small'}}>*TAEG fixe de 14.05%. Un crédit vous engage et doit être remboursé. Vérifiez vos capacités de remboursement avant de vous engager. Offre de crédit à partir de 200 €. Sous réserve d’acceptation par FLOA. Vous disposez d’un délai de rétractation. Consultez les conditions <a href="https://www.sikomobility.com/cgu" rel="noreferrer" target="_blank" style={{ borderBottom: '2px solid green', fontSize: 'x-small'}}> ici</a> <br></br>
                        *Hors assurance facultative</p>

                    ) :(
                        <p style={{fontSize: 'x-small', padding : '2px'}}>*Sous réserve d’acceptation par FLOA. Vous disposez un délai légal de rétractation. </p>
                    )}
                </div>
            </form>

            </LoadScript>
        </>
    );
}

export default Home;