import React from 'react';
import camionWebp from '../../Asset/webp/camion.webp';
import camionPng from '../../Asset/png/camion.png';
import horlogeWebp from '../../Asset/webp/horloge.webp';
import horlogePng from '../../Asset/png/horloge.png';
import outilsWebp from '../../Asset/webp/outils.webp';
import outilsPng from '../../Asset/png/outils.png';
import bouclierWebp from '../../Asset/webp/bouclier.webp';
import bouclierPng from '../../Asset/png/bouclier.png';
import { Link } from 'react-router-dom';
import { useState , useEffect } from 'react';
import Lottie from 'react-lottie-player'
import lottie_refus from './lottie_refus.json'; //chemin d'accès à votre fichier json d'animation
import lottie_success from './lottie_success.json'; //chemin d'accès à votre fichier json d'animation
import './Final.css';
import axios from 'axios';
import Recap from '../../Components/Recap/Recap';
import { useSelector } from 'react-redux';
import HelloSign from 'hellosign-embedded';

export default function Error() {

    localStorage.setItem('step', 'final');
    
    const [valid, setvalid] = useState(false);
    const token = useSelector((state) => state.token.value);

    const [data, setData] = useState({}); // nouvel état pour stocker les informations reçues

    const client = new HelloSign();

    const [isLoading, setIsLoading] = useState(true);

    const [serviceData, setServiceData] = useState([]);

    const [signButton, setSignButton] = useState(false);

    const [SignatureUrl, setSignatureUrl] = useState(false);

    useEffect(() => {
        const fetchBrands = async () => {
            try {
                setIsLoading(true); // Début du chargement
                const response = await axios.get('https://server.sikomobility.com/api/floa/fin', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                });
                setData(response.data); // mettre à jour l'état avec les données reçues
                console.log(response.data);

                const serviceData = [
                    {
                        serviceName: 'Assurance',
                        serviceContract: 'Assurance',
                        serviceContractValue: [response.data.InsuranceContractPrice],
                        serviceImage: [camionWebp, camionPng],
                        selectedOption: response.data.InsuranceContract,
                        serviceOption: ["Standard", "Premium"],
                        checked: response.data.InsuranceContract >= 1,
                    },
                    {
                        serviceName: 'Maintenance',
                        serviceContract: 'Maintenance',
                        serviceContractValue: [response.data.ReviewContractPrice],
                        serviceImage: [horlogeWebp, horlogePng],
                        selectedOption: response.data.ReviewContract,
                        serviceOption: false,
                        checked: response.data.ReviewContract >= 1,
                    },
                    {
                        serviceName: 'Assistance',
                        serviceContract: 'Assistance',
                        serviceContractValue: [response.data.SupportContractPrice],
                        serviceImage: [outilsWebp, outilsPng],
                        selectedOption: response.data.SupportContract,
                        serviceOption: false,
                        checked: response.data.SupportContract >= 1,
                    },
                    {
                        serviceName: 'Garantie',
                        serviceContract: 'Assurance',
                        serviceContractValue: [response.data.WarrantyContractPrice],
                        serviceImage: [bouclierWebp, bouclierPng],
                        selectedOption: response.data.WarrantyContract,
                        serviceOption: ["Standard", "Premium"],
                        checked: response.data.WarrantyContract >= 1,
                    },
                ];

                console.log(serviceData);
                setServiceData(serviceData);

                if (response.data.ReturnCode === "0") {
                    setvalid(true);

                    if ( response.data.Step === "Final"){

                        setSignButton(true);

                        const responseMail = await axios.get('https://server.sikomobility.com/api/floa/finalisation', {
                            headers: {
                                Authorization: `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            }
                        });
                        console.log(responseMail);

                        const response = await axios.get('https://server.sikomobility.com/api/contract/floa/generation', {
                            headers: {
                                Authorization: `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            }
                        });
                        console.log(response);

                        setSignatureUrl(response.data.SignatureUrl);
                        
                        client.open(response.data.SignatureUrl, {
                            clientId: "e6d75a3df32c6290f427ec16afee577d",
                            testMode: true,
                            skipDomainVerification: true
                        });
                        
                        client.on('sign', function(data) {
                            console.log('The document has been signed: ' + data.signatureId);
                        });
                        
                        client.on('error', function(data) {
                            console.error('There was an error: ' + data.errorCode + ' - ' + data.errorMsg);
                        })
                    }
                    
                    
                } else {
                    
                    setvalid(false);

                    if ( response.data.Step === "Final"){

                        const responseMail = await axios.get('https://server.sikomobility.com/api/floa/finalisation', {
                            headers: {
                                Authorization: `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            }
                        });
                        console.log(responseMail);

                    }
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false); // Fin du chargement
            }
        };
        
        fetchBrands();
    }, []);

    const signAgain = () => {
        client.open(SignatureUrl, {
            clientId: "e6d75a3df32c6290f427ec16afee577d",
            skipDomainVerification: true,   
            // ...additional options
        });
    }

    return (
        <>
            {isLoading ? (
                <>
                    <div className="loader_all">
                        <div className="loader">
                            <div className="box box0">
                                <div></div>
                            </div>
                            <div className="box box1">
                                <div></div>
                            </div>
                            <div className="box box2">
                                <div></div>
                            </div>
                            <div className="box box3">
                               <div></div>
                            </div>
                            <div className="box box4">
                                <div></div>
                            </div>
                            <div className="box box5">
                                <div></div>
                            </div>
                            <div className="box box6">
                                <div></div>
                            </div>
                            <div className="box box7">
                                <div></div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className='Content resultatGrid'>
                    {valid ? 
                        <>
                            <div className="divResult">
                                <div>
                                    <h1>Félicitations, votre paiement a été accepté !</h1>
                                    <p>Vous pouvez maintenant célébrer l'achat de votre {data.Brand} {data.Model}.</p>
                                    <p>Vous avez recu un mail confirmant votre commande, le vendeur va désormais se rapprocher de vous pour organiser la remise en mains!</p>
                                </div>
                                <Lottie loop animationData={lottie_success} play style={{ width: 100, height: 100 }}/>
                            </div>
                            {
                                signButton ? (
                                    <button className='buttonTall' style={{gridColumn: "1 / 3"}} onClick={signAgain}> Signature</button>
                                ) : (  
                                    <div style={{gridColumn: "1 / 3"}}></div>
                                )
                            }
                            
                        </> :
                        <>
                            <div className="divResult">
                                <div>
                                    <h1>Oups, votre paiement a été refusé.</h1>
                                    <p>Pas de panique, cela arrive même aux meilleurs d'entre nous. </p>
                                    <p>Votre carte est peut etre non éligible ou non alimentée, vous pouvez en essayer une autre.</p> 
                                    <p>Vous pouvez également refaire la demande au nom d'un proche.</p>
                                </div>
                                <Lottie loop animationData={lottie_refus} play style={{ width: 100, height: 100 }}/>
                            </div>
                            <Link to="/" className='buttonTall ResultLink' > Retour à l'accueil</Link>
                        </> 
                    }
                    <div className="resultat resultatClient" >

                        <div className="resultatContent">

                            <h3>CLIENT</h3>

                            <div>
                                <h4> Genre </h4>
                                <p>{data.Civility}</p>
                            </div>
                            <div>
                                <h4> Nom </h4>
                                <p>{data.LastName}</p>
                            </div>
                            <div>
                                <h4> Prénom </h4>
                                <p>{data.FirstName}</p>
                            </div>
                            <div>
                                <h4> Date de naissance </h4>
                                <p>{data.BirthDate}</p>  {/* Veuillez noter que le champ BirthDate n'était pas inclus dans les données que vous avez partagées, assurez-vous qu'il existe dans la réponse de l'API. */}
                            </div>
                            <div>
                                <h4> Code postal de naissance </h4>
                                <p>{data.BirthZipCode}</p>
                            </div>
                            <div>
                                <h4>Ville de naissance </h4>
                                <p>{data.BirthCity}</p>
                            </div>
                            
                        </div>

                        <div className="resultatContent">

                            <h3>CONTACT</h3>

                            <div>
                                <h4> Email </h4>
                                <p>{data.Email}</p>
                            </div>
                            
                            <div>
                                <h4> Téléphone </h4>
                                <p>{data.PhoneNumber}</p>
                            </div>

                        </div>

                        <div className="resultatContent">

                            <h3>ADRESSE DE FACTURATION</h3>

                            <div>
                                <h4> Adresse </h4>
                                <p>{data.Address}</p>
                            </div>
                            <div>
                                <h4> Code postal </h4>
                                <p>{data.ZipCode}</p>
                            </div>
                            <div>
                                <h4> Ville </h4>
                                <p>{data.City}</p>
                            </div>
                            
                        </div>

                        <div className="resultatContent">

                            <h3>VEHICULE</h3>

                            <div>
                                <h4> Type d’engin </h4>
                                <p>{data.VehicleType}</p>
                            </div>
                            <div>
                                <h4> Marque </h4>
                                <p>{data.Brand}</p>
                            </div>
                            <div>
                                <h4> Modèle </h4>
                                <p>{data.Model}</p>
                            </div>
                            
                        </div>

                    </div>

                    <div className="resultat">

                        <div className="resultatContent">

                            <h3>VOTRE COMMANDE</h3>

                            <div>
                                <h4> Produit </h4>
                                <p>{data.Brand}  {data.Model}</p>
                            </div>
                            {valid ?(
                                <div>
                                    <h4> Montant </h4>
                                    <p style={{paddingBottom:'0px'}}>{data.scheduledAmount[0]}€ <small> aujourd’hui </small> </p>
                                    <small style={{paddingLeft:'10px'}}> puis {data.scheduledAmount[1]} € pendant {data.MonthlyPayment-1} mois </small>
                                </div>
                            ) : (
                                <div>
                                    <h4> Montant du loyer </h4>
                                    <p>{data.Amount} € </p>
                                </div>
                            )}

                        </div>
                        {
                            (data.InsuranceContract !== 0 || data.ReviewContract !== 0 || data.SupportContract !== 0 || data.WarrantyContract !== 0) 
                            ? (
                                <div className="resultatContent resultatContentFlex">
                                    <h3>VOS SERVICES</h3>
                                    {
                                        data.ServicePayment === "ECHOUE" ? (
                                            <>
                                                <p style={{gridColumn: "1/3"}}>Le financement a bien été approuvé, cependant le paiement des services a échoué. </p>
                                                <p style={{gridColumn: "1/3"}}>Pas de panique, nous sommes conscients de la situation et nous vous recontacterons sous peu pour résoudre ce problème. </p>
                                            </>
                                        )
                                        : (
                                            <>
                                                {serviceData.map((service, index) => (
                                                    <Recap
                                                        serviceOption={{ checked: service.checked , selectedOption: service.selectedOption - 1 }}
                                                        serviceData={service}
                                                        key={index}
                                                        variant="true"    
                                                    />
                                                ))}
                                            </>
                                        )
                                    }
                                </div>
                            ) 
                            : null
                        }

                    </div>

                    {valid ?(
                        <>
                            <div className="resultat">                

                                <div className="resultatContent">

                                    <h3>ÉCHÉANCIER DU FINANCEMENT</h3>

                                    <div>
                                        <h4> Nombre de mensualités </h4>
                                        <p>{data.MonthlyPayment}</p>
                                    </div>
                                    <div>
                                        <h4>Première échéance</h4>
                                        <p>{data.scheduledDate[0]}</p>
                                    </div>
                                    <div>
                                        <h4>Dernière échéancee</h4>
                                        <p>{data.scheduledDate[data.scheduledDate.length - 1]}</p>
                                    </div>
                                    <div>
                                        <h4>Mensualité</h4>
                                        <p>{data.scheduledAmount[0]} €</p> 
                                    </div>
                                </div>

                            </div>
                        </>
                    ):(
                        <>
                        </>
                    )}                   
                </div>
            )}
        </>
        
    );
}