// import React, { useState, useEffect } from "react";
import "./Notif.css";
import Logo from '../../Asset/svg/icon.svg';

export default function Notif({ type, message, onClick }) {
    
    return (
        <div className={`Notif ${type}`} onClick={onClick}>
            <img src={Logo} alt="Logo" />
            <h4>
                {message}
            </h4>
        </div>
    );
}
