import "./Footer.css";
import logo from "../../Asset/svg/logoblack.svg";


export default function Navbar() {

    return (
        <div className="footer">

            <div className="grid_footer">
                <div>
                    <img src={logo} alt="logo"/>
                    <p><a href="mailto:contact@sikomobility.com">contact@sikomobility.com</a></p>
                    <p>01 89 71 34 21</p>
                    <p><a href="https://www.google.com/maps/place/86+Rue+Dutot,+75015+Paris/@48.8367952,2.3046339,17z/data=!3m1!4b1!4m5!3m4!1s0x47e670478765a801:0x3a670f5f2f9b2e47!8m2!3d48.8367952!4d2.3068226">86 rue Dutot 75015 Paris </a></p>
                    <p>Siko Mobility respecte des normes sociales et environnementales et s’inscrit dans une démarche de progrès.</p>
                </div>
                <div>
                    <h4 className="description">Informations</h4>
                    <a href="https://www.sikomobility.com/mentions-legales">Mentions légales</a>
                    <a href="https://www.sikomobility.com/cgu">Conditions générales de vente</a>
                    <a href="https://www.sikomobility.com/espace-presse">Espace presse</a>
                    <a href="https://www.sikomobility.com/politique-de-donnees-personnelles">Politique de confidentialité</a>
                    <a href="https://www.sikomobility.com/rgpd-et-cookies">RGPD & Cookies</a>
                </div>
                <div>
                    <h4 className="description">Siko Mobility</h4>
                    <a href="https://www.sikomobility.com">Accueil</a>
                    <a href="https://www.sikomobility.com/trottinettes">Nos offres de véhicule</a>
                    <a href="https://www.sikomobility.com/services">Nos services</a>
                    <a href="https://www.sikomobility.com/qui-nous-sommes">Qui sommes-nous</a>
                    <a href="https://www.sikomobility.com/faq-clients">FAQ</a>
                </div>
            </div>
            <div>
                <small className="description">Siko Mobility est une société par actions simplifiée au capital de 15 000.00 €,
                    inscrite au registre du commerce et des sociétés de Paris sous le numéro 918 822 131. Opérant sous la
                    marque Patinette, la société est régie par le Codes des Assurances, immatriculée au Registre ORIAS sous
                    le numéro 190 041 24.</small>
            </div>

        </div>
    );
}
