import React from 'react';
import Modal from '../Modal/Modal';
import './Card.css';
import check from '../../Asset/svg/check.svg';

const Card = ({ serviceOption, serviceData, toggleServicePopup, handleOptionChange, pop, toggleCheckbox, index, VehicleType }) => {
    return (
        <div className={`div_service ${serviceOption.checked ? "div_check" : ""}`} id={`${serviceData.serviceName}Div`}>
            <div className="legend" id={`legend${serviceData.serviceName}`}>
                <p>{serviceData.serviceLegend}</p>
            </div>

            <picture>
                <source srcSet={serviceData.serviceImage[0]} type="image/webp" />
                <source srcSet={serviceData.serviceImage[1]} type="image/png" />
                <img src={serviceData.serviceImage[0]} alt={serviceData.serviceName} />
            </picture>

            <img className="check" src={check} alt="check" />

            <div className="service_content">
                <h4>
                    <span>
                        {serviceData.serviceContract.toUpperCase()}

                        {serviceData.serviceOption && (
                            <select
                                name={`${serviceData.serviceName}Option`}
                                id={`${serviceData.serviceName}Option`}
                                value={serviceOption.selectedOption} // This will set the correct selected option
                                onChange={(event) => handleOptionChange(event, index)} // Pass index to handleOptionChange
                            >
                                {Array.isArray(serviceData.serviceOption) && serviceData.serviceOption.length > 0 && serviceData.serviceOption.map((option, index) => (
                                    <option value={index} key={index}>{option}</option>
                                ))}
                            </select>
                        )}
                    </span>
                    <img
                        src={pop}
                        className="imgPop"
                        id={`pop${serviceData.serviceName}`}
                        alt="pop"
                        onClick={() => toggleServicePopup(serviceData.serviceName)}
                    />

                    <Modal className={`popOverlay ${serviceOption.showPopup ? 'pop_open' : 'pop_close'}`}>
                        <h3>Détail de votre {serviceData.serviceContract}</h3>
                        <ul className='whiteUl'>
                            {Array.isArray(serviceData.servicePop[serviceOption.selectedOption]) ? (
                                // If service has options
                                serviceData.servicePop[serviceOption.selectedOption].map((content, index) => (
                                    <li key={index}>{content}</li>
                                ))
                            ) : (
                                // If service does not have options
                                serviceData.servicePop.map((content, index) => (
                                    <li key={index}>{content}</li>
                                ))
                            )}
                        </ul>
                        <div className='buttonDiv'>
                            <p className="buttonTall" id={`pop${serviceData.serviceName}close`} onClick={() => toggleServicePopup(serviceData.serviceName)}> Fermer </p>
                        </div>
                    </Modal>

                </h4>

                {serviceData.serviceOption !== false ? (
                    // If serviceOption is not false (meaning the service has options)
                    // Display different content based on selectedOption
                    <ul>
                        {serviceData.serviceContent.map((content, index) => (
                            <li key={index}>{content}</li>
                        ))}
                    </ul>
                ) : (
                    // If serviceOption is false (meaning the service does not have options)
                    <ul>
                        {Array.isArray(serviceData.serviceContent) && serviceData.serviceContent.map((pop, index) => (
                            <li key={index}>{pop}</li>
                        ))}
                    </ul>
                )}
                <div className="checkbox-rect">
                    <input
                        type="checkbox"
                        id={`${serviceData.serviceName}Contract`}
                        value={serviceData.serviceContract}
                        name={`${serviceData.serviceName}Contract`}
                        checked={serviceOption.checked}
                        onChange={() => toggleCheckbox(index)} // Use index to indicate which serviceOption to change
                    />
                </div>
            </div>

            <div className="price" id={`${serviceData.serviceName}Price`} onClick={() => toggleCheckbox(index)}>
                <div className="price_flex">
                    {(VehicleType === 'Moto' || VehicleType === "Scooter") && serviceData.serviceName === "Insurance" ? (
                        <p>À partir</p>
                    ) : null}
                    {Array.isArray(serviceData.serviceContractValue) ? (
                        // Si le service a des options
                        <div className='priceDiv'>
                            <h2 id={`h2${serviceData.serviceName}`}>
                                {Math.floor(serviceData.serviceContractValue[serviceOption.selectedOption])}
                            </h2>
                            <div>
                                <p id={`p${serviceData.serviceName}`}>
                                    € {(serviceData.serviceContractValue[serviceOption.selectedOption] % 1).toFixed(2).substring(2)}
                                </p>
                                <p>/ mois</p>
                            </div>
                        </div>
                    ) : (
                        // Si le service n'a pas d'options
                        <>
                            <h2 id={`h2${serviceData.serviceName}`}>
                                {Math.floor(serviceData.serviceContractValue)}
                            </h2>
                            <div>
                                <p id={`p${serviceData.serviceName}`}>
                                    € {(serviceData.serviceContractValue % 1).toFixed(2).substring(2)}
                                </p>
                                <p>/ mois</p>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Card;
