import React from "react";
import './Modal.css';

const Modal = ({className, children}) => {
  
    return (
        <div className={className}>
            <div className="pop">
                {children}
            </div>
        </div>
    );
};

export default Modal;