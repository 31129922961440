const Recap = ({ serviceOption, serviceData ,variant }) => (
    serviceOption.checked && serviceData && (
        <div id={`pop_${serviceData.serviceName}`} className={variant ?("serviceOpen serviceVariant"):("serviceOpen")}>
            <div className="sous_pop_content">
                <div className="recapDiv">
                    <picture>
                        <source srcSet={serviceData.serviceImage[0]} type="image/webp" />
                        <source srcSet={serviceData.serviceImage[1]} type="image/png" />
                        <img src={serviceData.serviceImage[0]} alt={serviceData.serviceName} />
                    </picture>
                </div>
                <div>
                    <h4>{serviceData.serviceContract}</h4>
                    {Array.isArray(serviceData.serviceOption) && serviceData.serviceOption[serviceOption.selectedOption] && 
                        <p>Formule : {serviceData.serviceOption[serviceOption.selectedOption]} </p> 
                    }
                </div>
            </div>
            {Array.isArray(serviceData.serviceContractValue) && serviceData.serviceContractValue[serviceOption.selectedOption] && (
                <h3>{serviceData.serviceContractValue[serviceOption.selectedOption]}€</h3>
            )}
        </div>
    )
);

export default Recap;