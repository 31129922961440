import './Pay.css';
import React from "react";
import { useState , useEffect } from 'react';
import bouleWebp from '../../Asset/webp/boule.webp';
import boulePng from '../../Asset/png/boule.png';
import camionWebp from '../../Asset/webp/camion.webp';
import camionPng from '../../Asset/png/camion.png';
import horlogeWebp from '../../Asset/webp/horloge.webp';
import horlogePng from '../../Asset/png/horloge.png';
import outilsWebp from '../../Asset/webp/outils.webp';
import outilsPng from '../../Asset/png/outils.png';
import bouclierWebp from '../../Asset/webp/bouclier.webp';
import bouclierPng from '../../Asset/png/bouclier.png';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { setStep } from '../../Redux/slice';
import { useDispatch } from 'react-redux';
import Recap from '../../Components/Recap/Recap';
import Notif from '../../Components/Notif/Notif';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';

const stripePromise = loadStripe('pk_live_zgJOtbFxozXZvs9R82yfOFCB008Xdr25Om');

const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleStripe = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    
    const cardNumber = elements.getElement(CardNumberElement);
    elements.getElement(CardCvcElement);
    elements.getElement(CardExpiryElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumber,
    });

    setIsLoading(true);

    if (error) {
        setIsLoading(false);
        console.error(error);
        setNotification({ type: 'error', message: error.message});
    } else {

        try {
        
            const response = await axios.post('https://server.sikomobility.com/api/floa/redirection2', {
                PaymentMethod: paymentMethod.id,
            }, { headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });

            setIsLoading(false);

            dispatch(setStep('final')); // Mise à jour du step
            window.location.href = response.data.PaymentUrl;

        } catch (error) {
            setIsLoading(false);
            setNotification({ type: 'error', message: `Verfier les informations de votre carte bancaire`});
        }
    }
  };

  localStorage.setItem('step', 'pay');

    const [notification, setNotification] = useState(null);

    const closeNotification = () => {
        setNotification(null);
    };
    const dispatch = useDispatch();

    const token = useSelector((state) => state.token.value);

    const [price, setPrice] = useState(0);

    const [isLoading, setIsLoading] = useState(false);

    const  [servicesData, setservicesData] = useState( [
        {
            serviceName: "Insurance",
            serviceContract: "Assurance",
            serviceContractValue: [[9.99]],
            serviceImage: [bouclierWebp,bouclierPng],
            checked: false,
            selectedOption: 0,
            serviceOption: ["Standard", "Premium"],
        },
        {
            serviceName: "Warranty",
            serviceContract: "Garantie",
            serviceContractValue: [[9.99]],
            serviceImage: [horlogeWebp,horlogePng],
            checked: false,
            selectedOption: 0,
            serviceOption: ["Standard", "Premium"],
        },
        {
            serviceName: "Maintenance",
            serviceContract: "Entretien",
            serviceContractValue: [[9.99]],
            serviceImage: [camionWebp,camionPng],
            checked: false,
            selectedOption: 0,
        },
        {
            serviceName: "Assistance",
            serviceContract: "Assistance",
            serviceContractValue: [[9.99]],
            serviceImage: [outilsWebp,outilsPng],
            checked: false,
            selectedOption: 0,
        }
    ]);

    const [paymentChoice] = useState(false);
    // const [paymentChoice, setpaymentChoice] = useState(false);

    // const togglePaymentChoice = () => {
    //     setpaymentChoice((prevPaymentChoice) => !prevPaymentChoice);
    // };

    const [payData, setPayData] = useState({
        Iban: "",
        AcceptIban: false,
        Card: "",
        expirationMonth: "",
        expirationYear: "",
        CVC: "",
        AcceptCard: false
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "expiration") {
            const [year, month] = value.split("-");
            setPayData((prevData) => ({
                ...prevData,
                expirationMonth: month,
                expirationYear: year,
            }));
        } else {
            setPayData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true); // Début du chargement
                const response = await axios.get('https://server.sikomobility.com/api/floa/paiement', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                });
                // console.log(response);
                
                if (response.data.status === "1") {
                    // Mise à jour des données de services
                    let updatedServices = [...servicesData];
    
                    updatedServices.forEach(service => {
                        switch (service.serviceName) {
                            case "Insurance":
                                service.checked = response.data.InsuranceContract >= 1;
                                service.serviceContractValue = [[response.data.InsuranceContractPrice]];
                                break;
                            case "Warranty":
                                service.checked = response.data.WarrantyContract >= 1;
                                service.serviceContractValue = [[response.data.WarrantyContractPrice]];
                                break;
                            case "Maintenance":
                                service.checked = response.data.ReviewContract >= 1;
                                service.serviceContractValue = [[response.data.ReviewContractPrice]];
                                break;
                            case "Assistance":
                                service.checked = response.data.SupportContract >= 1;
                                service.serviceContractValue = [[response.data.SupportContractPrice]];
                                break;
                            default:
                                break;
                        }
                    });
    
                    setservicesData(updatedServices);
                    setPrice(parseFloat(response.data.Total));
                } else {
                    // Statut = 0
                    setservicesData(prevServices => prevServices.map(service => ({...service, checked: false})));
                    setPrice(0);
                }
    
            } catch (error) {
                console.error(error);
            }finally {
                setIsLoading(false); // Fin du chargement
            }
        };
    
        fetchData();
    }, []);

  return (
    <>

        
    <Notif
        type={notification && notification.type}
        message={notification && notification.message}
        onClick={closeNotification}
    />
    {isLoading ? (
        <>
            <div className="loader_all">
                <div className="loader">
                    <div className="box box0">
                        <div></div>
                    </div>
                    <div className="box box1">
                        <div></div>
                    </div>
                    <div className="box box2">
                        <div></div>
                    </div>
                    <div className="box box3">
                        <div></div>
                    </div>
                    <div className="box box4">
                        <div></div>
                    </div>
                    <div className="box box5">
                        <div></div>
                    </div>
                    <div className="box box6">
                        <div></div>
                    </div>
                    <div className="box box7">
                        <div></div>
                    </div>
                </div>
            </div>
        </>
    ) : (
        <form onSubmit={handleStripe}>
            <h1 className='titelPay'>VOS COORDONNÉES BANCAIRES</h1>
            <div className={price > 0 ? "form SplitService" : "form"}   >

                    {/* <div className="switch_box">
                        <span>IBAN</span>
                        <input type="checkbox" className="switch" id="paymentChoice" name="paymentChoice" onChange={togglePaymentChoice} />
                        <span>CARTE BANCAIRE</span>
                    </div> */}

                    { paymentChoice ? (
                        <div>

                            <div className="inputDiv">
                                <input type="text" className="required" id="Iban" name="Iban" value={payData.Iban} onChange={handleInputChange} required />
                                <span></span>
                                <label htmlFor="Iban">Iban</label>
                            </div>

                            <div className="Accept">
                                <div>
                                    <input type="checkbox" className="switch" name="AcceptIban" id="AcceptIban" checked={payData.AcceptIban} onChange={(e) => handleInputChange({target: {name: e.target.name, value: e.target.checked}})} />
                                </div>
                                <span htmlFor="Accept">En réglant par prélèvement bancaire, vous acceptez que vos garanties soient activées uniquement si votre première cotisation est encaissée dans les 25 jours suivant la signature de votre contrat, ou au plus tard le mois précédent le démarrage de votre contrat. Les sinistres survenant dans ce délai ne seront pas couverts en cas de non-paiement. <a target="_blank" rel="noreferrer" href="https://www.sikomobility.com/politique-de-donnees-personnelles">Voici la Politique de confidentialité et les CGU de Siko</a>.</span>
                            </div>

                        </div>
                    ) : (
                        <div>
                            <div className="inputDiv">
                            <CardNumberElement />
                            </div>
                            <div className="inputDiv">
                            <CardExpiryElement />
                            </div>
                            <div className="inputDiv">
                            <CardCvcElement />
                            </div>
                            <div className="Accept">
                                <div>
                                    <input type="checkbox" className="switch" name="AcceptCard" id="AcceptCard" checked={payData.AcceptCard} required onChange={(e) => handleInputChange({target: {name: e.target.name, value: e.target.checked}})} />
                                </div>
                                <span htmlFor="Accept">En réglant par carte bancaire, vous acceptez que SikoMobility et Stripe traitent vos données bancaires à des fins de gestion de votre contrat, tel que défini dans notre <a target="_blank" rel="noreferrer" href="https://www.sikomobility.com/politique-de-donnees-personnelles">Politique de confidentialité</a> et vous acceptez les <a target="_blank" rel="noreferrer" href="https://www.sikomobility.com/cgu">CGU de SikoMobility</a> et <a target="_blank" rel="noreferrer" href="https://stripe.com/fr/privacy">Stripe</a>.</span>
                            </div>
                        </div>
                        
                    )}

                    {price > 0 ? (
                        <div className='ServicePrice'>
                            <div>
                                <h4> TOTAL SERVICE : </h4>
                            </div>
                            {servicesData.map(serviceOption => {
                                const serviceData = servicesData.find(data => data.serviceName === serviceOption.serviceName);

                                return (
                                    <Recap
                                        key={serviceOption.serviceName}
                                        serviceOption={serviceOption}
                                        serviceData={serviceData}
                                    />
                                );
                            })}
                        </div>
                        ) : (<></>
                    )}
                        

                <picture id="b1" className="boule">
                    <source srcSet={bouleWebp} type="image/webp" />
                    <source srcSet={boulePng} type="image/png" />
                    <img src={bouleWebp} alt="boule" />
                </picture>

                <picture id="b2" className="boule">
                    <source srcSet={bouleWebp} type="image/webp" />
                    <source srcSet={boulePng}  type="image/png" />
                    <img src={bouleWebp} alt="boule" />
                </picture>

            </div>

            <div className='ConditionPay' >
                <small>
                    En cliquant sur "Je ne veux aucun service" vous acceptez que vos données personnelles soient transmises et traitées par FLOA dans le cadre de votre demande de paiement en plusieurs fois.
                    Plus d'informations <a href="https://www.floabank.fr/politique-confidentialite" target="_blank" rel="noreferrer" style={{ borderBottom: '2px solid green', fontSize: 'x-small'}}><small>ici</small>.</a>
                </small>
                <small>
                    En cliquant sur "Je ne veux aucun service" vous avez pris connaissance et acceptez les conditions générales du paiement en plusieurs fois de FLOA
                    Voir <a href="https://www.floabank.fr/conditions-generales-paiement-plusieurs-fois" target="_blank" rel="noreferrer" style={{ borderBottom: '2px solid green', fontSize: 'x-small'}}> <small>ici</small>.</a>
                </small>
            </div>

            <div className="send">
                <div className="recap_service">
                    {price > 0 ? (
                        <div style={{flexDirection:"column"}}>
                            <p>La première échéance comptera double le premier et le deuxième mois.</p>
                            <p>Tous les ans, vous serez facturé(e) une taxe attentat de 5.90€.</p>
                        </div>
                    ) : (
                        <p>Aucun service n'a été pris. Nous avons besoin de la carte uniquement pour Floa </p>
                    )}
                </div>

                <div className="recap_price">
                {price > 0 ? (
                    <div>
                        <h4><span id="text_1">{price}</span>€ / mois</h4>
                    </div>
                    ) : (<></>
                )}
                    <button type="submit" className="button" id="open_pop">Suivant</button>

                </div>
            </div>
        </form>
    )}
</>
    
  );
};

const StripeContainer = () => (
  <Elements stripe={stripePromise}>
    <PaymentForm />
  </Elements>
);

export default StripeContainer;
