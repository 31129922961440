import './App.css';
import { Routes ,Route } from 'react-router-dom';
import Home from "./Containers/Home/Home";
import Service from "./Containers/Service/Service";
import Error from "./Containers/Error/Error";
import Footer from './Components/Footer/Footer'
import Header from './Components/Header/Header'
import Pay from './Containers/Pay/Pay'
import Final from './Containers/Final/Final'
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import ProgressBar from './Components/ProgressBar/ProgressBar';

function App() {

    const isLoggedIn = useSelector((state) => state.token.value !== null)
    const step = useSelector((state) => state.token.step);

    return (
        <div className="App">
            <Header/>
            <ProgressBar step={step}/>
            <Routes>
                <Route path="/" element={<Home />}/>
                <Route path="/:firstName/:lastName/:birthCity/:birthDate/:birthZipCode/:address/:zipCode/:city/:phoneNumber/:email/:vehicleType/:brand/:model/:amount/:monthlyPayment/:merchant/:merchantEmail" element={<Home />}/>
                <Route path="/Service" element={isLoggedIn ? <Service /> : <Navigate to="/" /> }/>
                <Route path="/Pay" element={isLoggedIn ? <Pay /> :  <Navigate to="/" />}/>
                <Route path="/final" element={isLoggedIn ? <Final /> :  <Navigate to="/" />}/>
                <Route path="*" element={<Error />}/>
            </Routes>
            <Footer/> 
        </div>
    );
}

export default App;