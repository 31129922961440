import React from 'react';
import './ProgressBar.css';
import logo from '../../Asset/svg/icon.svg';

function ProgressBar({ step }) {
    let progressValue = 0;

    switch(step) {
        case 'home':
            progressValue = 0;
            break;
        case 'service':
            progressValue = 33;
            break;
        case 'pay':
            progressValue = 66;
            break;
        case 'final':
            progressValue = 100;
            break;
        default:
            progressValue = 0;
            break;
    }

    return (
        <div className="progress-container">
            <img src={logo} alt="logo" style={{ left: `${progressValue}%` }} />
            <div className="progress-bar" style={{ width: `${progressValue}%` }}>
                
            </div>
            <div className={`progress-point step1 active `}></div>
            <div className={`progress-point step2 ${step === 'service' || step === 'pay' || step === 'final' ? 'active' : ''}`}></div>
            <div className={`progress-point step3 ${step === 'pay' || step === 'final' ? 'active' : ''}`}></div>
            <div className={`progress-point step4 ${step === 'final' ? 'active' : ''}`}></div>
        </div>
    );
}

export default ProgressBar;
