import React from 'react'
import { Link } from 'react-router-dom'
import './Error.css'
import Eror404 from "../../Asset/png/Eror404.png"

export default function Error(){
    return (
      <div className='Content ErorDiv'>
        <img id="Eror" src={Eror404} alt="Eror404" />
        <h1>Oups ...</h1>
        <h4>La page n'a pas été trouvée : un mystère de plus à résoudre.</h4>
        <Link className="buttonTall" to='/'>Back</Link>
      </div>
    )
}
