// tokenSlice.js
import { createSlice } from '@reduxjs/toolkit';

const tokenSlice = createSlice({
    name: 'token',
    initialState: {
        value: localStorage.getItem('token'),
        step: localStorage.getItem('step'), 
    },
    reducers: {
        sendToken: (state, action) => {
            state.value = action.payload;
        },
        setStep: (state, action) => {
            state.step = action.payload;
        }
    },
});

export const { sendToken, setStep } = tokenSlice.actions;

export default tokenSlice.reducer;
