// Table de mappage entre les paramètres et les données correspondantes.
const DATA_MAP = {
    Insurance: {
        Velo: {
            Premium: {
                InsuranceContent: [
                    "- Valable en Europe ",
                    "- Accessoires fixes couverts en cas de vol",
                    "- Couverture contre le vol et les dommages en cas d'accident, de choc ou de chute"
                ],
                InsurancePop: [
                    "Casse et Couverture contre le vol nuit et jour",
                    "  - Le véhicule doit être attaché à un point fixe à l'aide d'un antivol agréé aux normes SRA, FUB, ART, Sold Secure. Vous devez disposer d’une facture à votre nom.",
                    "  - Franchises pour le dommage et le vol : 10% de la valeur d’achat pour la casse ou le vol vélo sans vétusté dans les 24 mois, puis 1% par mois.",
                    "  - Pour tous les vélos de moins de 5 ans et leurs accessoires fixes ajoutés (hors speedbikes)",
                    "  - Assistance 24/7 en cas de sinistre",
                    "  - Couverture contre les dommages matériels subis en cas d'accident, de choc ou de chute",
                    "  - Couverture contre les dommages effectués par un tiers",
                    "Vous référer à vos Conditions Particulières d'Assurance."
                ],
                InsuranceLegend: ["75€ offerts en bon d'achat"],
            },
            Standard: {
                InsuranceContent: [
                    "Couverture contre le vol nuit et jour",
                    "Prise en charge des dommages au bien d'autrui",
                    "Protection juridique motard"
                ],
                InsurancePop: [
                    "Individuelle accident vous protégeant en cas de perte de capacité suite à un accident de la route (dommages corporels)",
                    "  - Remboursement du véhicule en cas de vol",
                    "  - Le véhicule doit être attaché à un point fixe à l'aide d'un antivol agréé aux normes SRA, FUB, ART, Sold Secure. Vous devez disposer d’une facture à votre nom.",
                    "  - Franchises pour le dommage et le vol : 10% de la valeur d’achat pour la casse ou le vol vélo sans vétusté dans les 24 mois, puis 1% par mois.",
                    "Vous référer à vos Conditions Particulières d'Assurance."
                ],
                InsuranceLegend: ["INDISPENSABLE EN VILLE"],
            },
        },
        VeloCargo: {
            Premium: {
                InsuranceContent: [
                    "- Valable en Europe ",
                    "- Accessoires fixes couverts en cas de vol",
                    "- Couverture contre le vol et les dommages en cas d'accident, de choc ou de chute"
                ],
                InsurancePop: [
                    "Casse et Couverture contre le vol nuit et jour",
                    "  - Le véhicule doit être attaché à un point fixe à l'aide d'un antivol agréé aux normes SRA, FUB, ART, Sold Secure. Vous devez disposer d’une facture à votre nom.",
                    "  - Franchises pour le dommage et le vol : 10% de la valeur d’achat pour la casse ou le vol vélo sans vétusté dans les 24 mois, puis 1% par mois.",
                    "  - Pour tous les vélos de moins de 5 ans et leurs accessoires fixes ajoutés (hors speedbikes)",
                    "  - Assistance 24/7 en cas de sinistre",
                    "  - Couverture contre les dommages matériels subis en cas d'accident, de choc ou de chute",
                    "  - Couverture contre les dommages effectués par un tiers",
                    "Vous référer à vos Conditions Particulières d'Assurance."
                ],
                InsuranceLegend: ["75€ offerts en bon d'achat"],
            },
            Standard: {
                InsuranceContent: [
                    "Couverture contre le vol nuit et jour",
                    "Prise en charge des dommages au bien d'autrui",
                    "Protection juridique motard"
                ],
                InsurancePop: [
                    "Individuelle accident vous protégeant en cas de perte de capacité suite à un accident de la route (dommages corporels)",
                    "- Remboursement du véhicule en cas de vol",
                    "- Le véhicule doit être attaché à un point fixe à l'aide d'un antivol agréé aux normes SRA, FUB, ART, Sold Secure. Vous devez disposer d’une facture à votre nom.",
                    "- Franchises pour le dommage et le vol : 10% de la valeur d’achat pour la casse ou le vol vélo sans vétusté dans les 24 mois, puis 1% par mois.",
                    "Vous référer à vos Conditions Particulières d'Assurance."
                ],
                InsuranceLegend: ["INDISPENSABLE EN VILLE"],
            },
        },
        Trottinette: {
            Premium: {
                InsuranceContent: [
                    "- Responsabilité civile tiers",
                    "- Garantie Corporelle du Conducteur",
                    "- Vol avec Agression",
                ],
                InsurancePop: [
                    "Tous Risques :",
                    "  - Responsabilité civile tiers",
                    "  - Garantie Corporelle du Conducteur",
                    "  - Dommage collision avec un tiers identifié",
                    "  - Vol avec Agression",
                    "  - 75€ en bon d'achat par an",
                    "Vous référer à vos Conditions Particulières d'Assurance."
                ],
                InsuranceLegend: ["75€ offerts en bon d'achat"],
            },
            Standard: {
                InsuranceContent: [
                    "- Remboursement des dommages à autrui",
                    "- Prise en charge des dommages au bien d'autrui",
                    "- Protection juridique motard",
                ],
                InsurancePop: [
                    "Tiers :",
                    "  - Responsabilité civile tiers",
                    "Vous référer à vos Conditions Particulières d'Assurance."
                ],
                InsuranceLegend: ["OBLIGATOIRE"],
            },
        },
        Moto: {
            Premium: {
                InsuranceContent: [
                    "- Responsabilité civile tiers",
                    "- Garantie Corporelle du Conducteur",
                    "- Casse / Vol",
                ],
                InsurancePop: [
                    "- Tous Risques :",
                    "  - Responsabilité civile tiers",
                    "  - Garantie Corporelle du Conducteur",
                    "  - Casse / Vol",
                    "Vous référer à vos Conditions Particulières d'Assurance."
                ],

                InsuranceLegend: ["Couverture contre le vol nuit et jour"],
            },
            Standard: {
                InsuranceContent: [
                    "- Remboursement des dommages à autrui",
                    "- Prise en charge des dommages au bien d'autrui",
                    "- Protection juridique motard",
                ],
                InsurancePop: [
                    "Tiers :",
                    "  - Responsabilité civile tiers",
                    "Vous référer à vos Conditions Particulières d'Assurance."
                ],
                InsuranceLegend: ["OBLIGATOIRE"],
            },
        },
        Scooter: {
            Premium: {
                InsuranceContent: [
                    "- Responsabilité civile tiers",
                    "- Garantie Corporelle du Conducteur",
                    "- Casse / Vol",
                ],
                InsurancePop: [
                    "- Tous Risques :",
                    "  - Responsabilité civile tiers",
                    "  - Garantie Corporelle du Conducteur",
                    "  - Casse / Vol",
                    "Vous référer à vos Conditions Particulières d'Assurance."
                ],

                InsuranceLegend: ["Pack premium"],
            },
            Standard: {
                InsuranceContent: [
                    "- Remboursement des dommages à autrui",
                    "- Prise en charge des dommages au bien d'autrui",
                    "- Protection juridique motard",
                ],
                InsurancePop: [
                    "Tiers :",
                    "  - Responsabilité civile tiers",
                    "Vous référer à vos Conditions Particulières d'Assurance."
                ],
                InsuranceLegend: ["OBLIGATOIRE"],
            },
        },
    },
    Warranty: {
        Velo: {
            Standard: {
                1500: {
                    WarrantyContent: [
                        "- Remboursement pièce et main d'oeuvre en année 3 pour tout type de panne",
                        "- Nombre illimité d'interventions dans la limite de 300 € / intervention",
                        "- Valable dans n'importe quel garage"
                    ],
                    WarrantyPop: [
                        "Illimité au garage de votre choix valable sur 8 points de contrôle, avec un plafond de  300 € / intervention :",
                        "  - Moteur/contrôleur VAE : moteur électrique et réducteur mécanique si moins de 30000kms.",
                        "  - Modules électroniques : calculateur de gestion contrôle moteur, convertisseur/régulateur d'alimentation moteur, boitier de commande électrique moteur.",
                        "  - Capteurs électroniques : capteurs directement liés aux calculateurs du point \"Modules électroniques\".",
                        "  - Transmissions : cassette arrière et roue libre, dérailleur, plateau.",
                        "  - Cadre/fourche : défaut mécano-soudure (sauf en cas de choc ou perforation).",
                        "  - Freins : mécanisme de frein hors pièces en friction, d'usures et câbles.",
                        "  - Batteries d'alimentation principale moteur : la garantie couvre la défaillance fortuite et non l'usure normale, le reconditionnement des modules, sa réparations ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "  - Alimentation externe : module régulateur/onduleur de charge des batteries.",
                        "L'usure normale, le reconditionnement des modules, sa réparations ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "A l'exception de: l'usure normale, les chocs, les ingrédients, les petites fournitures, les durites et flexibles, les courroies et chaînes, câbles et les joints, et les faisceaux électriques.",
                        "Condition d'entretien la batterie principale :",
                        "La laisser chargée minimum à 20%; ne pas la laisser déchargée pendant de longues périodes ni en plein soleil; la conserver et à une température comprise entre 0c° et 40c°; La négligence et la non-utilisation pendant une longue durée sont à l'origine de la majorité des problèmes de batterie. Si vous n’utilisez pas votre véhicule pendant un mois ou plus, conservez la batterie à 60% de sa capacité minimum et déconnectée du véhicule et chargez-la au moins une fois tous les trois mois.",
                        "Vous référer à vos conditions particulières."
                    ],
                    WarrantyLegend: ["COUVERTURE CONTRE LE VOL NUIT ET JOUR"],
                },
                2500: {
                    WarrantyContent: [
                        "- Remboursement pièce et main d'oeuvre en année 3 pour tout type de panne",
                        "- Nombre illimité d'interventions dans la limite de 500 € / intervention",
                        "- Valable dans n'importe quel garage"
                    ],
                    WarrantyPop: [
                        "Illimité au garage de votre choix valable sur 8 points de contrôle, avec un plafond de  500 € / intervention :",
                        "  - Moteur/contrôleur VAE : moteur électrique et réducteur mécanique si moins de 30000kms.",
                        "  - Modules électroniques : calculateur de gestion contrôle moteur, convertisseur/régulateur d'alimentation moteur, boitier de commande électrique moteur.",
                        "  - Capteurs électroniques : capteurs directement liés aux calculateurs du point \"Modules électroniques\".",
                        "  - Transmissions : cassette arrière et roue libre, dérailleur, plateau.",
                        "  - Cadre/fourche : défaut mécano-soudure (sauf en cas de choc ou perforation).",
                        "  - Freins : mécanisme de frein hors pièces en friction, d'usures et câbles.",
                        "  - Batteries d'alimentation principale moteur : la garantie couvre la défaillance fortuite et non l'usure normale, le reconditionnement des modules, sa réparations ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "  - Alimentation externe : module régulateur/onduleur de charge des batteries.",
                        "L'usure normale, le reconditionnement des modules, sa réparations ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "A l'exception de: l'usure normale, les chocs, les ingrédients, les petites fournitures, les durites et flexibles, les courroies et chaînes, câbles et les joints, et les faisceaux électriques.",
                        "Condition d'entretien la batterie principale :",
                        "La laisser chargée minimum à 20%; ne pas la laisser déchargée pendant de longues périodes ni en plein soleil; la conserver et à une température comprise entre 0c° et 40c°; La négligence et la non-utilisation pendant une longue durée sont à l'origine de la majorité des problèmes de batterie. Si vous n’utilisez pas votre véhicule pendant un mois ou plus, conservez la batterie à 60% de sa capacité minimum et déconnectée du véhicule et chargez-la au moins une fois tous les trois mois.",
                        "Vous référer à vos conditions particulières."
                    ],
                    WarrantyLegend: ["Zéro franchise, zéro plafond"],
                },
                7000: {
                    WarrantyContent: [
                        "- Remboursement pièce et main d'oeuvre en année 3 pour tout type de panne",
                        "- Nombre illimité d'interventions dans la limite de 800 € / intervention",
                        "- Valable dans n'importe quel garage"
                    ],
                    WarrantyPop: [
                        "Illimité au garage de votre choix valable sur 8 points de contrôle, avec un plafond de  800 € / intervention :",
                        "  - Moteur/contrôleur VAE : moteur électrique et réducteur mécanique si moins de 30000kms.",
                        "  - Modules électroniques : calculateur de gestion contrôle moteur, convertisseur/régulateur d'alimentation moteur, boitier de commande électrique moteur.",
                        "  - Capteurs électroniques : capteurs directement liés aux calculateurs du point \"Modules électroniques\".",
                        "  - Transmissions : cassette arrière et roue libre, dérailleur, plateau.",
                        "  - Cadre/fourche : défaut mécano-soudure (sauf en cas de choc ou perforation).",
                        "  - Freins : mécanisme de frein hors pièces en friction, d'usures et câbles.",
                        "  - Batteries d'alimentation principale moteur : la garantie couvre la défaillance fortuite et non l'usure normale, le reconditionnement des modules, sa réparations ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "  - Alimentation externe : module régulateur/onduleur de charge des batteries.",
                        "L'usure normale, le reconditionnement des modules, sa réparations ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "A l'exception de: l'usure normale, les chocs, les ingrédients, les petites fournitures, les durites et flexibles, les courroies et chaînes, câbles et les joints, et les faisceaux électriques.",
                        "Condition d'entretien la batterie principale :",
                        "La laisser chargée minimum à 20%; ne pas la laisser déchargée pendant de longues périodes ni en plein soleil; la conserver et à une température comprise entre 0c° et 40c°; La négligence et la non-utilisation pendant une longue durée sont à l'origine de la majorité des problèmes de batterie. Si vous n’utilisez pas votre véhicule pendant un mois ou plus, conservez la batterie à 60% de sa capacité minimum et déconnectée du véhicule et chargez-la au moins une fois tous les trois mois.",
                        "Vous référer à vos conditions particulières."
                    ],
                    WarrantyLegend: ["Zéro franchise, zéro plafond"],
                },
                15000: {
                    WarrantyContent: [
                        "- Remboursement pièce et main d'oeuvre en année 3 pour tout type de panne",
                        "- Nombre illimité d'interventions dans la limite de 1000 € / intervention",
                        "- Valable dans n'importe quel garage"
                    ],
                    WarrantyPop: [
                        "Illimité au garage de votre choix valable sur 8 points de contrôle, avec un plafond de  1000 € / intervention :",
                        "  - Moteur/contrôleur VAE : moteur électrique et réducteur mécanique si moins de 30000kms.",
                        "  - Modules électroniques : calculateur de gestion contrôle moteur, convertisseur/régulateur d'alimentation moteur, boitier de commande électrique moteur.",
                        "  - Capteurs électroniques : capteurs directement liés aux calculateurs du point \"Modules électroniques\".",
                        "  - Transmissions : cassette arrière et roue libre, dérailleur, plateau.",
                        "  - Cadre/fourche : défaut mécano-soudure (sauf en cas de choc ou perforation).",
                        "  - Freins : mécanisme de frein hors pièces en friction, d'usures et câbles.",
                        "  - Batteries d'alimentation principale moteur : la garantie couvre la défaillance fortuite et non l'usure normale, le reconditionnement des modules, sa réparations ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "  - Alimentation externe : module régulateur/onduleur de charge des batteries.",
                        "L'usure normale, le reconditionnement des modules, sa réparations ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "A l'exception de: l'usure normale, les chocs, les ingrédients, les petites fournitures, les durites et flexibles, les courroies et chaînes, câbles et les joints, et les faisceaux électriques.",
                        "Condition d'entretien la batterie principale :",
                        "La laisser chargée minimum à 20%; ne pas la laisser déchargée pendant de longues périodes ni en plein soleil; la conserver et à une température comprise entre 0c° et 40c°; La négligence et la non-utilisation pendant une longue durée sont à l'origine de la majorité des problèmes de batterie. Si vous n’utilisez pas votre véhicule pendant un mois ou plus, conservez la batterie à 60% de sa capacité minimum et déconnectée du véhicule et chargez-la au moins une fois tous les trois mois.",
                        "Vous référer à vos conditions particulières."
                    ],
                    WarrantyLegend: ["Zéro franchise, zéro plafond"],
                },
            
            },
            Premium: {
                1500: {
                    WarrantyContent: [
                        "- Remboursement pièce et main d'oeuvre en année 3 pour tout type de panne",
                        "- Nombre illimité d'interventions dans la limite de 600 € / intervention",
                        "- Valable dans n'importe quel garage"
                    ],
                    WarrantyPop: [
                        "Illimité au garage de votre choix valable sur 8 points de contrôle, avec un plafond de  600 € / intervention :",
                        "  - Moteur/contrôleur VAE : moteur électrique et réducteur mécanique si moins de 30000kms.",
                        "  - Modules électroniques : calculateur de gestion contrôle moteur, convertisseur/régulateur d'alimentation moteur, boitier de commande électrique moteur.",
                        "  - Capteurs électroniques : capteurs directement liés aux calculateurs du point \"Modules électroniques\".",
                        "  - Transmissions : cassette arrière et roue libre, dérailleur, plateau.",
                        "  - Cadre/fourche : défaut mécano-soudure (sauf en cas de choc ou perforation).",
                        "  - Freins : mécanisme de frein hors pièces en friction, d'usures et câbles.",
                        "  - Batteries d'alimentation principale moteur : la garantie couvre la défaillance fortuite et non l'usure normale, le reconditionnement des modules, sa réparations ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "  - Alimentation externe : module régulateur/onduleur de charge des batteries.",
                        "L'usure normale, le reconditionnement des modules, sa réparations ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "A l'exception de: l'usure normale, les chocs, les ingrédients, les petites fournitures, les durites et flexibles, les courroies et chaînes, câbles et les joints, et les faisceaux électriques.",
                        "Condition d'entretien la batterie principale :",
                        "La laisser chargée minimum à 20%; ne pas la laisser déchargée pendant de longues périodes ni en plein soleil; la conserver et à une température comprise entre 0c° et 40c°; La négligence et la non-utilisation pendant une longue durée sont à l'origine de la majorité des problèmes de batterie. Si vous n’utilisez pas votre véhicule pendant un mois ou plus, conservez la batterie à 60% de sa capacité minimum et déconnectée du véhicule et chargez-la au moins une fois tous les trois mois.",
                        "Vous référer à vos conditions particulières."
                    ],
                    WarrantyLegend: ["Zéro franchise, zéro plafond"],
                },
                2500: {
                    WarrantyContent: [
                        "- Remboursement pièce et main d'oeuvre en année 3 pour tout type de panne",
                        "- Nombre illimité d'interventions dans la limite de 1000 € / intervention",
                        "- Valable dans n'importe quel garage"
                    ],
                    WarrantyPop: [
                        "Illimité au garage de votre choix valable sur 8 points de contrôle, avec un plafond de  1000 € / intervention :",
                        "  - Moteur/contrôleur VAE : moteur électrique et réducteur mécanique si moins de 30000kms.",
                        "  - Modules électroniques : calculateur de gestion contrôle moteur, convertisseur/régulateur d'alimentation moteur, boitier de commande électrique moteur.",
                        "  - Capteurs électroniques : capteurs directement liés aux calculateurs du point \"Modules électroniques\".",
                        "  - Transmissions : cassette arrière et roue libre, dérailleur, plateau.",
                        "  - Cadre/fourche : défaut mécano-soudure (sauf en cas de choc ou perforation).",
                        "  - Freins : mécanisme de frein hors pièces en friction, d'usures et câbles.",
                        "  - Batteries d'alimentation principale moteur : la garantie couvre la défaillance fortuite et non l'usure normale, le reconditionnement des modules, sa réparations ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "  - Alimentation externe : module régulateur/onduleur de charge des batteries.",
                        "L'usure normale, le reconditionnement des modules, sa réparations ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "A l'exception de: l'usure normale, les chocs, les ingrédients, les petites fournitures, les durites et flexibles, les courroies et chaînes, câbles et les joints, et les faisceaux électriques.",
                        "Condition d'entretien la batterie principale :",
                        "La laisser chargée minimum à 20%; ne pas la laisser déchargée pendant de longues périodes ni en plein soleil; la conserver et à une température comprise entre 0c° et 40c°; La négligence et la non-utilisation pendant une longue durée sont à l'origine de la majorité des problèmes de batterie. Si vous n’utilisez pas votre véhicule pendant un mois ou plus, conservez la batterie à 60% de sa capacité minimum et déconnectée du véhicule et chargez-la au moins une fois tous les trois mois.",
                        "Vous référer à vos conditions particulières."
                    ],
                    WarrantyLegend: ["Zéro franchise, zéro plafond"],
                },
                7000: {
                    WarrantyContent: [
                        "- Remboursement pièce et main d'oeuvre en année 3 pour tout type de panne",
                        "- Nombre illimité d'interventions dans la limite de 1600 € / intervention",
                        "- Valable dans n'importe quel garage"
                    ],
                    WarrantyPop: [
                        "Illimité au garage de votre choix valable sur 8 points de contrôle, avec un plafond de  1600 € / intervention :",
                        "  - Moteur/contrôleur VAE : moteur électrique et réducteur mécanique si moins de 30000kms.",
                        "  - Modules électroniques : calculateur de gestion contrôle moteur, convertisseur/régulateur d'alimentation moteur, boitier de commande électrique moteur.",
                        "  - Capteurs électroniques : capteurs directement liés aux calculateurs du point \"Modules électroniques\".",
                        "  - Transmissions : cassette arrière et roue libre, dérailleur, plateau.",
                        "  - Cadre/fourche : défaut mécano-soudure (sauf en cas de choc ou perforation).",
                        "  - Freins : mécanisme de frein hors pièces en friction, d'usures et câbles.",
                        "  - Batteries d'alimentation principale moteur : la garantie couvre la défaillance fortuite et non l'usure normale, le reconditionnement des modules, sa réparations ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "  - Alimentation externe : module régulateur/onduleur de charge des batteries.",
                        "L'usure normale, le reconditionnement des modules, sa réparations ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "A l'exception de: l'usure normale, les chocs, les ingrédients, les petites fournitures, les durites et flexibles, les courroies et chaînes, câbles et les joints, et les faisceaux électriques.",
                        "Condition d'entretien la batterie principale :",
                        "La laisser chargée minimum à 20%; ne pas la laisser déchargée pendant de longues périodes ni en plein soleil; la conserver et à une température comprise entre 0c° et 40c°; La négligence et la non-utilisation pendant une longue durée sont à l'origine de la majorité des problèmes de batterie. Si vous n’utilisez pas votre véhicule pendant un mois ou plus, conservez la batterie à 60% de sa capacité minimum et déconnectée du véhicule et chargez-la au moins une fois tous les trois mois.",
                        "Vous référer à vos conditions particulières."
                    ],
                    WarrantyLegend: ["Zéro franchise, zéro plafond"],
                },
                15000: {
                    WarrantyContent: [
                        "- Remboursement pièce et main d'oeuvre en année 3 pour tout type de panne",
                        "- Nombre illimité d'interventions dans la limite de 2000 € / intervention",
                        "- Valable dans n'importe quel garage"
                    ],
                    WarrantyPop: [
                        "Illimité au garage de votre choix valable sur 8 points de contrôle, avec un plafond de  2000 € / intervention :",
                        "  - Moteur/contrôleur VAE : moteur électrique et réducteur mécanique si moins de 30000kms.",
                        "  - Modules électroniques : calculateur de gestion contrôle moteur, convertisseur/régulateur d'alimentation moteur, boitier de commande électrique moteur.",
                        "  - Capteurs électroniques : capteurs directement liés aux calculateurs du point \"Modules électroniques\".",
                        "  - Transmissions : cassette arrière et roue libre, dérailleur, plateau.",
                        "  - Cadre/fourche : défaut mécano-soudure (sauf en cas de choc ou perforation).",
                        "  - Freins : mécanisme de frein hors pièces en friction, d'usures et câbles.",
                        "  - Batteries d'alimentation principale moteur : la garantie couvre la défaillance fortuite et non l'usure normale, le reconditionnement des modules, sa réparations ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "  - Alimentation externe : module régulateur/onduleur de charge des batteries.",
                        "L'usure normale, le reconditionnement des modules, sa réparations ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "A l'exception de: l'usure normale, les chocs, les ingrédients, les petites fournitures, les durites et flexibles, les courroies et chaînes, câbles et les joints, et les faisceaux électriques.",
                        "Condition d'entretien la batterie principale :",
                        "La laisser chargée minimum à 20%; ne pas la laisser déchargée pendant de longues périodes ni en plein soleil; la conserver et à une température comprise entre 0c° et 40c°; La négligence et la non-utilisation pendant une longue durée sont à l'origine de la majorité des problèmes de batterie. Si vous n’utilisez pas votre véhicule pendant un mois ou plus, conservez la batterie à 60% de sa capacité minimum et déconnectée du véhicule et chargez-la au moins une fois tous les trois mois.",
                        "Vous référer à vos conditions particulières."
                    ],
                    WarrantyLegend: ["Zéro franchise, zéro plafond"],
                },
            },
        },
        Trottinette: {
            Standard: {
                1500: {
                    WarrantyContent: [
                        "- Remboursement pièce et main d'oeuvre en année 3 pour tout type de panne",
                        "- Nombre illimité d'interventions dans la limite de 300 € / intervention",
                        "- Valable dans n'importe quel garage"
                    ],
                    WarrantyPop: [
                        "Illimité au garage de votre choix valable sur 6 points de contrôle, avec un plafond de  300 € / intervention :",
                        "  - Moteur/contrôleur VAE : moteur électrique et réducteur mécanique si moins de 30000kms.",
                        "  - Modules électroniques : calculateur de gestion contrôle moteur, convertisseur/régulateur d'alimentation moteur, boitier de commande électrique moteur.",
                        "  - Capteurs électroniques : capteurs directement liés aux calculateurs du point \"Modules électroniques\".",
                        "  - Ecran/afficheur : écran d'affichage sauf en cas de choc. ",
                        "  - Alimentation externe : module régulateur/onduleur de charge des batteries.",
                        "  - Batteries d'alimentation principale moteur : la garantie couvre la défaillance fortuite et non l'usure normale, le reconditionnement des modules, sa réparations ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "L'usure normale, le reconditionnement des modules, sa réparations ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "A l'exception de: l'usure normale, les chocs, les ingrédients, les petites fournitures, les durites et flexibles, les courroies et chaînes, câbles et les joints, et les faisceaux électriques.",
                        "Condition d'entretien la batterie principale :",
                        "La laisser chargée minimum à 20%; ne pas la laisser déchargée pendant de longues périodes ni en plein soleil; la conserver et à une température comprise entre 0c° et 40c°; La négligence et la non-utilisation pendant une longue durée sont à l'origine de la majorité des problèmes de batterie. Si vous n’utilisez pas votre véhicule pendant un mois ou plus, conservez la batterie à 60% de sa capacité minimum et déconnectée du véhicule et chargez-la au moins une fois tous les trois mois.",
                        "Vous référer à vos conditions particulières."
                    ],
                    WarrantyLegend: ["Zéro franchise, zéro plafond"],
                },
                3000: {
                    WarrantyContent: [
                        "- Remboursement pièce et main d'oeuvre en année 3 pour tout type de panne",
                        "- Nombre illimité d'interventions dans la limite de 500 € / intervention",
                        "- Valable dans n'importe quel garage"
                    ],
                    WarrantyPop: [
                        "Illimité au garage de votre choix valable sur 6 points de contrôle, avec un plafond de  500 € / intervention :",
                        "  - Moteur/contrôleur VAE : moteur électrique et réducteur mécanique si moins de 30000kms.",
                        "  - Modules électroniques : calculateur de gestion contrôle moteur, convertisseur/régulateur d'alimentation moteur, boitier de commande électrique moteur.",
                        "  - Capteurs électroniques : capteurs directement liés aux calculateurs du point \"Modules électroniques\".",
                        "  - Ecran/afficheur : écran d'affichage sauf en cas de choc. ",
                        "  - Alimentation externe : module régulateur/onduleur de charge des batteries.",
                        "  - Batteries d'alimentation principale moteur : la garantie couvre la défaillance fortuite et non l'usure normale, le reconditionnement des modules, sa réparations ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "L'usure normale, le reconditionnement des modules, sa réparations ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "A l'exception de: l'usure normale, les chocs, les ingrédients, les petites fournitures, les durites et flexibles, les courroies et chaînes, câbles et les joints, et les faisceaux électriques.",
                        "Condition d'entretien la batterie principale :",
                        "La laisser chargée minimum à 20%; ne pas la laisser déchargée pendant de longues périodes ni en plein soleil; la conserver et à une température comprise entre 0c° et 40c°; La négligence et la non-utilisation pendant une longue durée sont à l'origine de la majorité des problèmes de batterie. Si vous n’utilisez pas votre véhicule pendant un mois ou plus, conservez la batterie à 60% de sa capacité minimum et déconnectée du véhicule et chargez-la au moins une fois tous les trois mois.",
                        "Vous référer à vos conditions particulières."
                    ],
                    WarrantyLegend: ["Zéro franchise, zéro plafond"],
                },
                10000: {
                    WarrantyContent: [
                        "- Remboursement pièce et main d'oeuvre en année 3 pour tout type de panne",
                        "- Nombre illimité d'interventions dans la limite de 750 € / intervention",
                        "- Valable dans n'importe quel garage"
                    ],
                    WarrantyPop: [
                        "Illimité au garage de votre choix valable sur 6 points de contrôle, avec un plafond de  750 € / intervention :",
                        "  - Moteur/contrôleur VAE : moteur électrique et réducteur mécanique si moins de 30000kms.",
                        "  - Modules électroniques : calculateur de gestion contrôle moteur, convertisseur/régulateur d'alimentation moteur, boitier de commande électrique moteur.",
                        "  - Capteurs électroniques : capteurs directement liés aux calculateurs du point \"Modules électroniques\".",
                        "  - Ecran/afficheur : écran d'affichage sauf en cas de choc. ",
                        "  - Alimentation externe : module régulateur/onduleur de charge des batteries.",
                        "  - Batteries d'alimentation principale moteur : la garantie couvre la défaillance fortuite et non l'usure normale, le reconditionnement des modules, sa réparations ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "L'usure normale, le reconditionnement des modules, sa réparations ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "A l'exception de: l'usure normale, les chocs, les ingrédients, les petites fournitures, les durites et flexibles, les courroies et chaînes, câbles et les joints, et les faisceaux électriques.",
                        "Condition d'entretien la batterie principale :",
                        "La laisser chargée minimum à 20%; ne pas la laisser déchargée pendant de longues périodes ni en plein soleil; la conserver et à une température comprise entre 0c° et 40c°; La négligence et la non-utilisation pendant une longue durée sont à l'origine de la majorité des problèmes de batterie. Si vous n’utilisez pas votre véhicule pendant un mois ou plus, conservez la batterie à 60% de sa capacité minimum et déconnectée du véhicule et chargez-la au moins une fois tous les trois mois.",
                        "Vous référer à vos conditions particulières."
                    ],
                    WarrantyLegend: ["Zéro franchise, zéro plafond"],
                },
            },
            Premium: {
                1500: {
                    WarrantyContent: [
                        "- Remboursement pièce et main d'oeuvre en année 3 pour tout type de panne",
                        "- Nombre illimité d'interventions dans la limite de 600 € / intervention",
                        "- Valable dans n'importe quel garage"
                    ],
                    WarrantyPop: [
                        "Illimité au garage de votre choix valable sur 6 points de contrôle, avec un plafond de  600 € / intervention :",
                        "  - Moteur/contrôleur VAE : moteur électrique et réducteur mécanique si moins de 30000kms.",
                        "  - Modules électroniques : calculateur de gestion contrôle moteur, convertisseur/régulateur d'alimentation moteur, boitier de commande électrique moteur.",
                        "  - Capteurs électroniques : capteurs directement liés aux calculateurs du point \"Modules électroniques\".",
                        "  - Ecran/afficheur : écran d'affichage sauf en cas de choc. ",
                        "  - Alimentation externe : module régulateur/onduleur de charge des batteries.",
                        "  - Batteries d'alimentation principale moteur : la garantie couvre la défaillance fortuite et non l'usure normale, le reconditionnement des modules, sa réparations ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "L'usure normale, le reconditionnement des modules, sa réparations ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "A l'exception de: l'usure normale, les chocs, les ingrédients, les petites fournitures, les durites et flexibles, les courroies et chaînes, câbles et les joints, et les faisceaux électriques.",
                        "Condition d'entretien la batterie principale :",
                        "La laisser chargée minimum à 20%; ne pas la laisser déchargée pendant de longues périodes ni en plein soleil; la conserver et à une température comprise entre 0c° et 40c°; La négligence et la non-utilisation pendant une longue durée sont à l'origine de la majorité des problèmes de batterie. Si vous n’utilisez pas votre véhicule pendant un mois ou plus, conservez la batterie à 60% de sa capacité minimum et déconnectée du véhicule et chargez-la au moins une fois tous les trois mois.",
                        "Vous référer à vos conditions particulières."
                    ],
                    WarrantyLegend: ["Zéro franchise, zéro plafond"],
                },
                3000: {
                    WarrantyContent: [
                        "- Remboursement pièce et main d'oeuvre en année 3 pour tout type de panne",
                        "- Nombre illimité d'interventions dans la limite de 1000€ / intervention",
                        "- Valable dans n'importe quel garage"
                    ],
                    WarrantyPop: [
                        "Illimité au garage de votre choix valable sur 6 points de contrôle, avec un plafond de  1000€ € / intervention :",
                        "  - Moteur/contrôleur VAE : moteur électrique et réducteur mécanique si moins de 30000kms.",
                        "  - Modules électroniques : calculateur de gestion contrôle moteur, convertisseur/régulateur d'alimentation moteur, boitier de commande électrique moteur.",
                        "  - Capteurs électroniques : capteurs directement liés aux calculateurs du point \"Modules électroniques\".",
                        "  - Ecran/afficheur : écran d'affichage sauf en cas de choc. ",
                        "  - Alimentation externe : module régulateur/onduleur de charge des batteries.",
                        "  - Batteries d'alimentation principale moteur : la garantie couvre la défaillance fortuite et non l'usure normale, le reconditionnement des modules, sa réparations ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "L'usure normale, le reconditionnement des modules, sa réparations ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "A l'exception de: l'usure normale, les chocs, les ingrédients, les petites fournitures, les durites et flexibles, les courroies et chaînes, câbles et les joints, et les faisceaux électriques.",
                        "Condition d'entretien la batterie principale :",
                        "La laisser chargée minimum à 20%; ne pas la laisser déchargée pendant de longues périodes ni en plein soleil; la conserver et à une température comprise entre 0c° et 40c°; La négligence et la non-utilisation pendant une longue durée sont à l'origine de la majorité des problèmes de batterie. Si vous n’utilisez pas votre véhicule pendant un mois ou plus, conservez la batterie à 60% de sa capacité minimum et déconnectée du véhicule et chargez-la au moins une fois tous les trois mois.",
                        "Vous référer à vos conditions particulières."
                    ],
                    WarrantyLegend: ["Zéro franchise, zéro plafond"],
                },
                10000: {
                    WarrantyContent: [
                        "- Remboursement pièce et main d'oeuvre en année 3 pour tout type de panne",
                        "- Nombre illimité d'interventions dans la limite de 1500 € / intervention",
                        "- Valable dans n'importe quel garage"
                    ],
                    WarrantyPop: [
                        "Illimité au garage de votre choix valable sur 6 points de contrôle, avec un plafond de  1500 € / intervention :",
                        "  - Moteur/contrôleur VAE : moteur électrique et réducteur mécanique si moins de 30000kms.",
                        "  - Modules électroniques : calculateur de gestion contrôle moteur, convertisseur/régulateur d'alimentation moteur, boitier de commande électrique moteur.",
                        "  - Capteurs électroniques : capteurs directement liés aux calculateurs du point \"Modules électroniques\".",
                        "  - Ecran/afficheur : écran d'affichage sauf en cas de choc. ",
                        "  - Alimentation externe : module régulateur/onduleur de charge des batteries.",
                        "  - Batteries d'alimentation principale moteur : la garantie couvre la défaillance fortuite et non l'usure normale, le reconditionnement des modules, sa réparations ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "L'usure normale, le reconditionnement des modules, sa réparations ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "A l'exception de: l'usure normale, les chocs, les ingrédients, les petites fournitures, les durites et flexibles, les courroies et chaînes, câbles et les joints, et les faisceaux électriques.",
                        "Condition d'entretien la batterie principale :",
                        "La laisser chargée minimum à 20%; ne pas la laisser déchargée pendant de longues périodes ni en plein soleil; la conserver et à une température comprise entre 0c° et 40c°; La négligence et la non-utilisation pendant une longue durée sont à l'origine de la majorité des problèmes de batterie. Si vous n’utilisez pas votre véhicule pendant un mois ou plus, conservez la batterie à 60% de sa capacité minimum et déconnectée du véhicule et chargez-la au moins une fois tous les trois mois.",
                        "Vous référer à vos conditions particulières."
                    ],
                    WarrantyLegend: ["Zéro franchise, zéro plafond"],
                },
            }
        },
        VeloCargo: {
            Standard: {
                7000: {
                    WarrantyContent: [
                        "- Remboursement pièce et main d'oeuvre en année 3 pour tout type de panne",
                        "- Nombre illimité d'interventions dans la limite de 1000 € / intervention",
                        "- Valable dans n'importe quel garage"
                    ],
                    WarrantyPop: [
                        "Illimité au garage de votre choix valable sur 8 points de contrôle, avec un plafond de  1000 € / intervention :",
                        "  - Moteur/contrôleur : moteur électrique et réducteur mécanique.",
                        "  - Modules électroniques : calculateur de gestion contrôle moteur, convertisseur/régulateur d'alimentation moteur, boitier de commande électrique moteur.",
                        "  - Capteurs électroniques : capteurs directement liés aux calculateurs du point \"Modules électroniques\".",
                        "  - Transmissions : cassette arrière et roue libre, dérailleur, plateau.",
                        "  - Cadre/fourche : défaut mécano-soudure (sauf en cas de choc ou perforation).",
                        "  - Freins : maître cylindre, système antiblocage des roues.",
                        "  - Batteries d'alimentation principale moteur : la garantie couvre la défaillance fortuite et non l'usure normale, le reconditionnement des modules, sa réparation ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "  - Alimentation externe : module régulateur/onduleur de charge des batteries.",
                        "Vous référer à vos conditions particulières."
                    ],

                    WarrantyLegend: ["Zéro franchise, zéro plafond"],
                },
                15000: {
                    WarrantyContent: [
                        "- Remboursement pièce et main d'oeuvre en année 3 pour tout type de panne",
                        "- Nombre illimité d'interventions dans la limite de 2000 € / intervention",
                        "- Valable dans n'importe quel garage"
                    ],
                    WarrantyPop: [
                        "Illimité au garage de votre choix valable sur 8 points de contrôle, avec un plafond de  2000 € / intervention :",
                        "  - Moteur/contrôleur : moteur électrique et réducteur mécanique.",
                        "  - Modules électroniques : calculateur de gestion contrôle moteur, convertisseur/régulateur d'alimentation moteur, boitier de commande électrique moteur.",
                        "  - Capteurs électroniques : capteurs directement liés aux calculateurs du point \"Modules électroniques\".",
                        "  - Transmissions : cassette arrière et roue libre, dérailleur, plateau.",
                        "  - Cadre/fourche : défaut mécano-soudure (sauf en cas de choc ou perforation).",
                        "  - Freins : maître cylindre, système antiblocage des roues.",
                        "  - Batteries d'alimentation principale moteur : la garantie couvre la défaillance fortuite et non l'usure normale, le reconditionnement des modules, sa réparation ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "  - Alimentation externe : module régulateur/onduleur de charge des batteries.",
                        "Vous référer à vos conditions particulières."
                    ],
                    WarrantyLegend: ["Zéro franchise, zéro plafond"],
                },
                30000: {
                    WarrantyContent: [
                        "- Remboursement pièce et main d'oeuvre en année 3 pour tout type de panne",
                        "- Nombre illimité d'interventions dans la limite de 3000 € / intervention",
                        "- Valable dans n'importe quel garage"
                    ],
                    WarrantyPop: [
                        "Illimité au garage de votre choix valable sur 8 points de contrôle, avec un plafond de  3000 € / intervention :",
                        "  - Moteur/contrôleur : moteur électrique et réducteur mécanique.",
                        "  - Modules électroniques : calculateur de gestion contrôle moteur, convertisseur/régulateur d'alimentation moteur, boitier de commande électrique moteur.",
                        "  - Capteurs électroniques : capteurs directement liés aux calculateurs du point \"Modules électroniques\".",
                        "  - Transmissions : cassette arrière et roue libre, dérailleur, plateau.",
                        "  - Cadre/fourche : défaut mécano-soudure (sauf en cas de choc ou perforation).",
                        "  - Freins : maître cylindre, système antiblocage des roues.",
                        "  - Batteries d'alimentation principale moteur : la garantie couvre la défaillance fortuite et non l'usure normale, le reconditionnement des modules, sa réparation ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "  - Alimentation externe : module régulateur/onduleur de charge des batteries.",
                        "Vous référer à vos conditions particulières."
                    ],
                    WarrantyLegend: ["Zéro franchise, zéro plafond"],
                },
            },
            Premium: {
                7000: {
                    WarrantyContent: [
                        "- Remboursement pièce et main d'oeuvre en année 3 pour tout type de panne",
                        "- Nombre illimité d'interventions dans la limite de 2000 € / intervention",
                        "- Valable dans n'importe quel garage"
                    ],
                    WarrantyPop: [
                        "Illimité au garage de votre choix valable sur 8 points de contrôle, avec un plafond de  2000 € / intervention :",
                        "  - Moteur/contrôleur : moteur électrique et réducteur mécanique.",
                        "  - Modules électroniques : calculateur de gestion contrôle moteur, convertisseur/régulateur d'alimentation moteur, boitier de commande électrique moteur.",
                        "  - Capteurs électroniques : capteurs directement liés aux calculateurs du point \"Modules électroniques\".",
                        "  - Transmissions : cassette arrière et roue libre, dérailleur, plateau.",
                        "  - Cadre/fourche : défaut mécano-soudure (sauf en cas de choc ou perforation).",
                        "  - Freins : maître cylindre, système antiblocage des roues.",
                        "  - Batteries d'alimentation principale moteur : la garantie couvre la défaillance fortuite et non l'usure normale, le reconditionnement des modules, sa réparation ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "  - Alimentation externe : module régulateur/onduleur de charge des batteries.",
                        "Vous référer à vos conditions particulières."
                    ],
                    WarrantyLegend: ["Zéro franchise, zéro plafond"],
                },
                15000: {
                    WarrantyContent: [
                        "- Remboursement pièce et main d'oeuvre en année 3 pour tout type de panne",
                        "- Nombre illimité d'interventions dans la limite de 4000 € / intervention",
                        "- Valable dans n'importe quel garage"
                    ],
                    WarrantyPop: [
                        "Illimité au garage de votre choix valable sur 8 points de contrôle, avec un plafond de  4000 € / intervention :",
                        "  - Moteur/contrôleur : moteur électrique et réducteur mécanique.",
                        "  - Modules électroniques : calculateur de gestion contrôle moteur, convertisseur/régulateur d'alimentation moteur, boitier de commande électrique moteur.",
                        "  - Capteurs électroniques : capteurs directement liés aux calculateurs du point \"Modules électroniques\".",
                        "  - Transmissions : cassette arrière et roue libre, dérailleur, plateau.",
                        "  - Cadre/fourche : défaut mécano-soudure (sauf en cas de choc ou perforation).",
                        "  - Freins : maître cylindre, système antiblocage des roues.",
                        "  - Batteries d'alimentation principale moteur : la garantie couvre la défaillance fortuite et non l'usure normale, le reconditionnement des modules, sa réparation ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "  - Alimentation externe : module régulateur/onduleur de charge des batteries.",
                        "Vous référer à vos conditions particulières."
                    ],
                    WarrantyLegend: ["Zéro franchise, zéro plafond"],
                },
                30000: {
                    WarrantyContent: [
                        "- Remboursement pièce et main d'oeuvre en année 3 pour tout type de panne",
                        "- Nombre illimité d'interventions dans la limite de 6000 € / intervention",
                        "- Valable dans n'importe quel garage"
                    ],
                    WarrantyPop: [
                        "Illimité au garage de votre choix valable sur 8 points de contrôle, avec un plafond de  6000 € / intervention :",
                        "  - Moteur/contrôleur : moteur électrique et réducteur mécanique.",
                        "  - Modules électroniques : calculateur de gestion contrôle moteur, convertisseur/régulateur d'alimentation moteur, boitier de commande électrique moteur.",
                        "  - Capteurs électroniques : capteurs directement liés aux calculateurs du point \"Modules électroniques\".",
                        "  - Transmissions : cassette arrière et roue libre, dérailleur, plateau.",
                        "  - Cadre/fourche : défaut mécano-soudure (sauf en cas de choc ou perforation).",
                        "  - Freins : maître cylindre, système antiblocage des roues.",
                        "  - Batteries d'alimentation principale moteur : la garantie couvre la défaillance fortuite et non l'usure normale, le reconditionnement des modules, sa réparation ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "  - Alimentation externe : module régulateur/onduleur de charge des batteries.",
                        "Vous référer à vos conditions particulières."
                    ],
                    WarrantyLegend: ["Zéro franchise, zéro plafond"],
                },
            }
        },
        Scooter: {
            Standard: {
                7000: {
                    WarrantyContent: [
                        "- Remboursement pièce et main d'oeuvre en année 3 pour tout type de panne",
                        "- Nombre illimité d'interventions dans la limite de 800 € / intervention",
                        "- Valable dans n'importe quel garage"
                    ],
                    WarrantyPop: [
                        "Illimité au garage de votre choix valable sur 8 points de contrôle, avec un plafond de  800 € / intervention :",
                        "  - Moteur/contrôleur : moteur électrique et réducteur mécanique.",
                        "  - Boîte de vitesse : éléments mobiles de la boîte (pignons, arbres, paliers, roulements, synchros, circlips, fourchettes de commande) ainsi que tous les dommages mécaniques portant sur le carter et résultant directement de l'avarie.",
                        "  - Embrayage et variateur: le dispositif d'embrayage si origine de la panne (sauf les pièces en friction).",
                        "  - Modules électroniques : calculateur de gestion contrôle moteur, convertisseur/régulateur d'alimentation moteur, boitier de commande électrique moteur.",
                        "  - Capteurs électroniques : capteurs directement liés aux calculateurs du point \"Modules électroniques\".",
                        "  - Freins : maître cylindre, système antiblocage des roues.",
                        "  - Batteries d'alimentation principale moteur : la garantie couvre la défaillance fortuite et non l'usure normale, le reconditionnement des modules, sa réparation ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "  - Alimentation externe : module régulateur/onduleur de charge des batteries.",
                        "Vous référer à vos conditions particulières."
                    ],
                    WarrantyLegend: ["Zéro franchise, zéro plafond"],
                },
                15000: {
                    WarrantyContent: [
                        "- Remboursement pièce et main d'oeuvre en année 3 pour tout type de panne",
                        "- Nombre illimité d'interventions dans la limite de 800 € / intervention",
                        "- Valable dans n'importe quel garage"
                    ],
                    WarrantyPop: [
                        "Illimité au garage de votre choix valable sur 8 points de contrôle, avec un plafond de  800 € / intervention :",
                        "  - Moteur/contrôleur : moteur électrique et réducteur mécanique.",
                        "  - Boîte de vitesse : éléments mobiles de la boîte (pignons, arbres, paliers roulements, synchros, circlips, fourchettes de commande) ainsi que tous les dommages mécaniques portant sur le carter et résultant directement de l'avarie.",
                        "  - Embrayage et variateur: le dispositif d'embrayage si origine de la panne (sauf les pièces en friction).",
                        "  - Modules électroniques : calculateur de gestion contrôle moteur, convertisseur/régulateur d'alimentation moteur, boitier de commande électrique moteur.",
                        "  - Capteurs électroniques : capteurs directement liés aux calculateurs du point \"Modules électroniques\".",
                        "  - Freins : maître cylindre, système antiblocage des roues.",
                        "  - Batteries d'alimentation principale moteur : la garantie couvre la défaillance fortuite et non l'usure normale, le reconditionnement des modules, sa réparation ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "  - Alimentation externe : module régulateur/onduleur de charge des batteries.",
                        "Vous référer à vos conditions particulières."
                    ],
                    WarrantyLegend: ["Zéro franchise, zéro plafond"],
                },
                30000: {
                    WarrantyContent: [
                        "- Remboursement pièce et main d'oeuvre en année 3 pour tout type de panne",
                        "- Nombre illimité d'interventions dans la limite de 800 € / intervention",
                        "- Valable dans n'importe quel garage"
                    ],
                    WarrantyPop: [
                        "Illimité au garage de votre choix valable sur 8 points de contrôle, avec un plafond de  800 € / intervention :",
                        "  - Moteur/contrôleur : moteur électrique et réducteur mécanique.",
                        "  - Boîte de vitesse : éléments mobiles de la boîte (pignons, arbres, paliers roulements, synchros, circlips, fourchettes de commande) ainsi que tous les dommages mécaniques portant sur le carter et résultant directement de l'avarie.",
                        "  - Embrayage et variateur: le dispositif d'embrayage si origine de la panne (sauf les pièces en friction).",
                        "  - Modules électroniques : calculateur de gestion contrôle moteur, convertisseur/régulateur d'alimentation moteur, boitier de commande électrique moteur.",
                        "  - Capteurs électroniques : capteurs directement liés aux calculateurs du point \"Modules électroniques\".",
                        "  - Freins : maître cylindre, système antiblocage des roues.",
                        "  - Batteries d'alimentation principale moteur : la garantie couvre la défaillance fortuite et non l'usure normale, le reconditionnement des modules, sa réparation ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "  - Alimentation externe : module régulateur/onduleur de charge des batteries.",
                        "Vous référer à vos conditions particulières."
                    ],
                    WarrantyLegend: ["Zéro franchise, zéro plafond"],
                },
            },
            Premium: {
                7000: {
                    WarrantyContent: [
                        "- Remboursement pièce et main d'oeuvre en année 3 pour tout type de panne",
                        "- Nombre illimité d'interventions dans la limite de 1600 € / intervention",
                        "- Valable dans n'importe quel garage"
                    ],
                    WarrantyPop: [
                        "Illimité au garage de votre choix valable sur 8 points de contrôle, avec un plafond de  1600 € / intervention :",
                        "  - Moteur/contrôleur : moteur électrique et réducteur mécanique.",
                        "  - Boîte de vitesse : éléments mobiles de la boîte (pignons, arbres, paliers roulements, synchros, circlips, fourchettes de commande) ainsi que tous les dommages mécaniques portant sur le carter et résultant directement de l'avarie.",
                        "  - Embrayage et variateur: le dispositif d'embrayage si origine de la panne (sauf les pièces en friction).",
                        "  - Modules électroniques : calculateur de gestion contrôle moteur, convertisseur/régulateur d'alimentation moteur, boitier de commande électrique moteur.",
                        "  - Capteurs électroniques : capteurs directement liés aux calculateurs du point \"Modules électroniques\".",
                        "  - Freins : maître cylindre, système antiblocage des roues.",
                        "  - Batteries d'alimentation principale moteur : la garantie couvre la défaillance fortuite et non l'usure normale, le reconditionnement des modules, sa réparation ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "  - Alimentation externe : module régulateur/onduleur de charge des batteries.",
                        "Vous référer à vos conditions particulières."
                    ],
                    WarrantyLegend: ["Zéro franchise, zéro plafond"],
                },
                15000: {
                    WarrantyContent: [
                        "- Remboursement pièce et main d'oeuvre en année 3 pour tout type de panne",
                        "- Nombre illimité d'interventions dans la limite de 1600 € / intervention",
                        "- Valable dans n'importe quel garage"
                    ],
                    WarrantyPop: [
                        "Illimité au garage de votre choix valable sur 8 points de contrôle, avec un plafond de  1600 € / intervention :",
                        "  - Moteur/contrôleur : moteur électrique et réducteur mécanique.",
                        "  - Boîte de vitesse : éléments mobiles de la boîte (pignons, arbres, paliers roulements, synchros, circlips, fourchettes de commande) ainsi que tous les dommages mécaniques portant sur le carter et résultant directement de l'avarie.",
                        "  - Embrayage et variateur: le dispositif d'embrayage si origine de la panne (sauf les pièces en friction).",
                        "  - Modules électroniques : calculateur de gestion contrôle moteur, convertisseur/régulateur d'alimentation moteur, boitier de commande électrique moteur.",
                        "  - Capteurs électroniques : capteurs directement liés aux calculateurs du point \"Modules électroniques\".",
                        "  - Freins : maître cylindre, système antiblocage des roues.",
                        "  - Batteries d'alimentation principale moteur : la garantie couvre la défaillance fortuite et non l'usure normale, le reconditionnement des modules, sa réparation ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "  - Alimentation externe : module régulateur/onduleur de charge des batteries.",
                        "Vous référer à vos conditions particulières."
                    ],
                    WarrantyLegend: ["Zéro franchise, zéro plafond"],
                },
                30000: {
                    WarrantyContent: [
                        "- Remboursement pièce et main d'oeuvre en année 3 pour tout type de panne",
                        "- Nombre illimité d'interventions dans la limite de 1600 € / intervention",
                        "- Valable dans n'importe quel garage"
                    ],
                    WarrantyPop: [
                        "Illimité au garage de votre choix valable sur 8 points de contrôle, avec un plafond de  1600 € / intervention :",
                        "  - Moteur/contrôleur : moteur électrique et réducteur mécanique.",
                        "  - Boîte de vitesse : éléments mobiles de la boîte (pignons, arbres, paliers roulements, synchros, circlips, fourchettes de commande) ainsi que tous les dommages mécaniques portant sur le carter et résultant directement de l'avarie.",
                        "  - Embrayage et variateur: le dispositif d'embrayage si origine de la panne (sauf les pièces en friction).",
                        "  - Modules électroniques : calculateur de gestion contrôle moteur, convertisseur/régulateur d'alimentation moteur, boitier de commande électrique moteur.",
                        "  - Capteurs électroniques : capteurs directement liés aux calculateurs du point \"Modules électroniques\".",
                        "  - Freins : maître cylindre, système antiblocage des roues.",
                        "  - Batteries d'alimentation principale moteur : la garantie couvre la défaillance fortuite et non l'usure normale, le reconditionnement des modules, sa réparation ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "  - Alimentation externe : module régulateur/onduleur de charge des batteries.",
                        "Vous référer à vos conditions particulières."
                    ],
                    WarrantyLegend: ["Zéro franchise, zéro plafond"],
                },
            }
        },
        Moto: {
            Standard: {
                7000: {
                    WarrantyContent: [
                        "- Remboursement pièce et main d'oeuvre en année 3 pour tout type de panne",
                        "- Nombre illimité d'interventions dans la limite de 800 € / intervention",
                        "- Valable dans n'importe quel garage"
                    ],
                    WarrantyPop: [
                        "Illimité au garage de votre choix valable sur 8 points de contrôle, avec un plafond de  800 € / intervention :",
                        "  - Moteur/contrôleur : moteur électrique et réducteur mécanique.",
                        "  - Boîte de vitesse : éléments mobiles de la boîte (pignons, arbres, paliers roulements, synchros, circlips, fourchettes de commande) ainsi que tous les dommages mécaniques portant sur le carter et résultant directement de l'avarie.",
                        "  - Embrayage et variateur: le dispositif d'embrayage si origine de la panne (sauf les pièces en friction).",
                        "  - Modules électroniques : calculateur de gestion contrôle moteur, convertisseur/régulateur d'alimentation moteur, boitier de commande électrique moteur.",
                        "  - Capteurs électroniques : capteurs directement liés aux calculateurs du point \"Modules électroniques\".",
                        "  - Freins : maître cylindre, système antiblocage des roues.",
                        "  - Batteries d'alimentation principale moteur : la garantie couvre la défaillance fortuite et non l'usure normale, le reconditionnement des modules, sa réparation ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "  - Alimentation externe : module régulateur/onduleur de charge des batteries.",
                        "Vous référer à vos conditions particulières."
                    ],
                    WarrantyLegend: ["Zéro franchise, zéro plafond"],
                },
                15000: {
                    WarrantyContent: [
                        "- Remboursement pièce et main d'oeuvre en année 3 pour tout type de panne",
                        "- Nombre illimité d'interventions dans la limite de 800 € / intervention",
                        "- Valable dans n'importe quel garage"
                    ],
                    WarrantyPop: [
                        "Illimité au garage de votre choix valable sur 8 points de contrôle, avec un plafond de  800 € / intervention :",
                        "  - Moteur/contrôleur : moteur électrique et réducteur mécanique.",
                        "  - Boîte de vitesse : éléments mobiles de la boîte (pignons, arbres, paliers roulements, synchros, circlips, fourchettes de commande) ainsi que tous les dommages mécaniques portant sur le carter et résultant directement de l'avarie.",
                        "  - Embrayage et variateur: le dispositif d'embrayage si origine de la panne (sauf les pièces en friction).",
                        "  - Modules électroniques : calculateur de gestion contrôle moteur, convertisseur/régulateur d'alimentation moteur, boitier de commande électrique moteur.",
                        "  - Capteurs électroniques : capteurs directement liés aux calculateurs du point \"Modules électroniques\".",
                        "  - Freins : maître cylindre, système antiblocage des roues.",
                        "  - Batteries d'alimentation principale moteur : la garantie couvre la défaillance fortuite et non l'usure normale, le reconditionnement des modules, sa réparation ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "  - Alimentation externe : module régulateur/onduleur de charge des batteries.",
                        "Vous référer à vos conditions particulières."
                    ],
                    WarrantyLegend: ["Zéro franchise, zéro plafond"],
                },
                30000: {
                    WarrantyContent: [
                        "- Remboursement pièce et main d'oeuvre en année 3 pour tout type de panne",
                        "- Nombre illimité d'interventions dans la limite de 800 € / intervention",
                        "- Valable dans n'importe quel garage"
                    ],
                    WarrantyPop: [
                        "Illimité au garage de votre choix valable sur 8 points de contrôle, avec un plafond de  800 € / intervention :",
                        "  - Moteur/contrôleur : moteur électrique et réducteur mécanique.",
                        "  - Boîte de vitesse : éléments mobiles de la boîte (pignons, arbres, paliers roulements, synchros, circlips, fourchettes de commande) ainsi que tous les dommages mécaniques portant sur le carter et résultant directement de l'avarie.",
                        "  - Embrayage et variateur: le dispositif d'embrayage si origine de la panne (sauf les pièces en friction).",
                        "  - Modules électroniques : calculateur de gestion contrôle moteur, convertisseur/régulateur d'alimentation moteur, boitier de commande électrique moteur.",
                        "  - Capteurs électroniques : capteurs directement liés aux calculateurs du point \"Modules électroniques\".",
                        "  - Freins : maître cylindre, système antiblocage des roues.",
                        "  - Batteries d'alimentation principale moteur : la garantie couvre la défaillance fortuite et non l'usure normale, le reconditionnement des modules, sa réparation ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "  - Alimentation externe : module régulateur/onduleur de charge des batteries.",
                        "Vous référer à vos conditions particulières."
                    ],
                    WarrantyLegend: ["Zéro franchise, zéro plafond"],
                },
            },
            Premium: {
                7000: {
                    WarrantyContent: [
                        "- Remboursement pièce et main d'oeuvre en année 3 pour tout type de panne",
                        "- Nombre illimité d'interventions dans la limite de 1600 € / intervention",
                        "- Valable dans n'importe quel garage"
                    ],
                    WarrantyPop: [
                        "Illimité au garage de votre choix valable sur 8 points de contrôle, avec un plafond de  1600 € / intervention :",
                        "  - Moteur/contrôleur : moteur électrique et réducteur mécanique.",
                        "  - Boîte de vitesse : éléments mobiles de la boîte (pignons, arbres, paliers roulements, synchros, circlips, fourchettes de commande) ainsi que tous les dommages mécaniques portant sur le carter et résultant directement de l'avarie.",
                        "  - Embrayage et variateur: le dispositif d'embrayage si origine de la panne (sauf les pièces en friction).",
                        "  - Modules électroniques : calculateur de gestion contrôle moteur, convertisseur/régulateur d'alimentation moteur, boitier de commande électrique moteur.",
                        "  - Capteurs électroniques : capteurs directement liés aux calculateurs du point \"Modules électroniques\".",
                        "  - Freins : maître cylindre, système antiblocage des roues.",
                        "  - Batteries d'alimentation principale moteur : la garantie couvre la défaillance fortuite et non l'usure normale, le reconditionnement des modules, sa réparation ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "  - Alimentation externe : module régulateur/onduleur de charge des batteries.",
                        "Vous référer à vos conditions particulières."
                    ],
                    WarrantyLegend: ["Zéro franchise, zéro plafond"],
                },
                15000: {
                    WarrantyContent: [
                        "- Remboursement pièce et main d'oeuvre en année 3 pour tout type de panne",
                        "- Nombre illimité d'interventions dans la limite de 1600 € / intervention",
                        "- Valable dans n'importe quel garage"
                    ],
                    WarrantyPop: [
                        "Illimité au garage de votre choix valable sur 8 points de contrôle, avec un plafond de  1600 € / intervention :",
                        "  - Moteur/contrôleur : moteur électrique et réducteur mécanique.",
                        "  - Boîte de vitesse : éléments mobiles de la boîte (pignons, arbres, paliers roulements, synchros, circlips, fourchettes de commande) ainsi que tous les dommages mécaniques portant sur le carter et résultant directement de l'avarie.",
                        "  - Embrayage et variateur: le dispositif d'embrayage si origine de la panne (sauf les pièces en friction).",
                        "  - Modules électroniques : calculateur de gestion contrôle moteur, convertisseur/régulateur d'alimentation moteur, boitier de commande électrique moteur.",
                        "  - Capteurs électroniques : capteurs directement liés aux calculateurs du point \"Modules électroniques\".",
                        "  - Freins : maître cylindre, système antiblocage des roues.",
                        "  - Batteries d'alimentation principale moteur : la garantie couvre la défaillance fortuite et non l'usure normale, le reconditionnement des modules, sa réparation ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "  - Alimentation externe : module régulateur/onduleur de charge des batteries.",
                        "Vous référer à vos conditions particulières."
                    ],
                    WarrantyLegend: ["Zéro franchise, zéro plafond"],
                },
                30000: {
                    WarrantyContent: [
                        "- Remboursement pièce et main d'oeuvre en année 3 pour tout type de panne",
                        "- Nombre illimité d'interventions dans la limite de 1600 € / intervention",
                        "- Valable dans n'importe quel garage"
                    ],
                    WarrantyPop: [
                        "Illimité au garage de votre choix valable sur 8 points de contrôle, avec un plafond de  1600 € / intervention :",
                        "  - Moteur/contrôleur : moteur électrique et réducteur mécanique.",
                        "  - Boîte de vitesse : éléments mobiles de la boîte (pignons, arbres, paliers roulements, synchros, circlips, fourchettes de commande) ainsi que tous les dommages mécaniques portant sur le carter et résultant directement de l'avarie.",
                        "  - Embrayage et variateur: le dispositif d'embrayage si origine de la panne (sauf les pièces en friction).",
                        "  - Modules électroniques : calculateur de gestion contrôle moteur, convertisseur/régulateur d'alimentation moteur, boitier de commande électrique moteur.",
                        "  - Capteurs électroniques : capteurs directement liés aux calculateurs du point \"Modules électroniques\".",
                        "  - Freins : maître cylindre, système antiblocage des roues.",
                        "  - Batteries d'alimentation principale moteur : la garantie couvre la défaillance fortuite et non l'usure normale, le reconditionnement des modules, sa réparation ou son changement, en appliquant un taux (%) de vétusté équivalent au taux (%) de sa perte d'autonomie (mesuré via testeur spécifique).",
                        "  - Alimentation externe : module régulateur/onduleur de charge des batteries.",
                        "Vous référer à vos conditions particulières."
                    ],
                    WarrantyLegend: ["Zéro franchise, zéro plafond"],
                },
            }
        },
    },
    Maintenance: {

        Velo: {
            Premium: {
                MaintenanceContent: [
                    "- Prise en charge des visites ateliers",
                    "- Valable dans n'importe quel garage ",
                    "- Vos passages sont prépayés"
                ],
                MaintenancePop: [
                    "1 Révision selon préconisation Constructeur par an au lieu de votre choix .",
                    "Pneumatiques pris en charge dans la limite du forfait.",
                    "Limité à 100€ de prise en charge par an.",
                ],
                MaintenanceLegend: ["Ne payez plus vos passages atelier"],

            },
            Standard: {
                MaintenanceContent: [
                    "- Prise en charge des visites ateliers",
                    "- Valable dans n'importe quel garage ",
                    "- Vos passages sont prépayés"
                ],
                MaintenancePop: [
                    "1 Révision selon préconisation Constructeur par an au lieu de votre choix .",
                    "Pneumatiques pris en charge dans la limite du forfait.",
                    "Limité à 100€ de prise en charge par an.",
                ],
                MaintenanceLegend: ["Ne payez plus vos passages atelier"],
            },
        },

        VeloCargo: {
            Premium: {
                MaintenanceContent: [
                    "- Prise en charge des visites ateliers",
                    "- Valable dans n'importe quel garage ",
                    "- Vos passages sont prépayés"
                ],
                MaintenancePop: [
                    "1 Révision selon préconisation Constructeur par an au lieu de votre choix .",
                    "Pneumatiques pris en charge dans la limite du forfait.",
                    "Limité à 100€ de prise en charge par an.",
                ],
                MaintenanceLegend: ["Ne payez plus vos passages atelier"],

            },
            Standard: {
                MaintenanceContent: [
                    "- Prise en charge des visites ateliers",
                    "- Valable dans n'importe quel garage ",
                    "- Vos passages sont prépayés"
                ],
                MaintenancePop: [
                    "1 Révision selon préconisation Constructeur par an au lieu de votre choix .",
                    "Pneumatiques pris en charge dans la limite du forfait.",
                    "Limité à 100€ de prise en charge par an.",
                ],
                MaintenanceLegend: ["Ne payez plus vos passages atelier"],
            },
        },
        
        Trottinette: {
            Premium: {
                MaintenanceContent: [
                    "- Prise en charge des visites ateliers",
                    "- Valable dans n'importe quel garage ",
                    "- Vos passages sont prépayés"
                ],
                MaintenancePop: [
                    "1 Révision selon préconisation Constructeur par an au lieu de votre choix .",
                    "Pneumatiques pris en charge dans la limite du forfait.",
                    "Limité à 100€ de prise en charge par an.",
                ],
                MaintenanceLegend: ["Ne payez plus vos passages atelier"],

            },
            Standard: {
                MaintenanceContent: [
                    "- Prise en charge des visites ateliers",
                    "- Valable dans n'importe quel garage ",
                    "- Vos passages sont prépayés"
                ],
                MaintenancePop: [
                    "1 Révision selon préconisation Constructeur par an au lieu de votre choix .",
                    "Pneumatiques pris en charge dans la limite du forfait.",
                    "Limité à 100€ de prise en charge par an.",
                ],
                MaintenanceLegend: ["Ne payez plus vos passages atelier"],
            },
        },
        
        Moto: {
            Premium: {
                MaintenanceContent: [
                    "- Prise en charge des visites ateliers",
                    "- Valable dans n'importe quel garage ",
                    "- Vos passages sont prépayés"
                ],
                MaintenancePop: [
                    "1 Révision selon préconisation Constructeur par an au lieu de votre choix .",
                    "Pneumatiques pris en charge dans la limite du forfait.",
                    "Limité à 100€ de prise en charge par an.",
                ],
                MaintenanceLegend: ["Ne payez plus vos passages atelier"],

            },
            Standard: {
                MaintenanceContent: [
                    "- Prise en charge des visites ateliers",
                    "- Valable dans n'importe quel garage ",
                    "- Vos passages sont prépayés"
                ],
                MaintenancePop: [
                    "1 Révision selon préconisation Constructeur par an au lieu de votre choix .",
                    "Pneumatiques pris en charge dans la limite du forfait.",
                    "Limité à 100€ de prise en charge par an.",
                ],
                MaintenanceLegend: ["Ne payez plus vos passages atelier"],
            },
        },

        Scooter: {
            Premium: {
                MaintenanceContent: [
                    "- Prise en charge des visites ateliers",
                    "- Valable dans n'importe quel garage ",
                    "- Vos passages sont prépayés"
                ],
                MaintenancePop: [
                    "1 Révision selon préconisation Constructeur par an au lieu de votre choix .",
                    "Pneumatiques pris en charge dans la limite du forfait.",
                    "Limité à 100€ de prise en charge par an.",
                ],
                MaintenanceLegend: ["Ne payez plus vos passages atelier"],

            },
            Standard: {
                MaintenanceContent: [
                    "- Prise en charge des visites ateliers",
                    "- Valable dans n'importe quel garage ",
                    "- Vos passages sont prépayés"
                ],
                MaintenancePop: [
                    "1 Révision selon préconisation Constructeur par an au lieu de votre choix .",
                    "Pneumatiques pris en charge dans la limite du forfait.",
                    "Limité à 100€ de prise en charge par an.",
                ],
                MaintenanceLegend: ["Ne payez plus vos passages atelier"],
            },
        },
    },
    Assistance: {

        Velo: {
            Premium: {
                AssistanceContent: [
                    "- Remorquage de votre véhicule sans franchise",
                    "- Mise à disposition d'un véhicule de remplacement",
                    "- Réseau national de dépannage 24/7"
                ],
                AssistancePop: [
                    "Dépannage/Remorquage 0km: Assistance 0km au véhicule en cas de panne, accident, vol ou tentative de vol, crevaison, perte ou vol des clés du véhicule, dans la limite de 200€ TTC et 50km.",
                    "Poursuite du voyage ou retour au domicile: Assistance au conducteur en cas de panne, accident, agression ou d’attente pour réparation à la suite d’un événement garantis. Titre de transport aller par bénéficiaire ou taxi. Dans la limite de 50 km autour du domicile ou lieu de villégiature.",
                    "Aide à la rédaction de constat amiable: 2 demandes par an.",
                    "Rapatriement du véhicule au domicile: 100€TTC dans la limite de 50km.",
                    "Véhicule de remplacement (Véhicule immobilisé plus de 24H) en cas de panne, accident: Remboursement de frais de véhicule de location. 25€TTC/Jour max 3 jours."
                ],
                AssistanceLegend: ["Plus jamais en rade"],

            },
            Standard: {
                AssistanceContent: [
                    "- Remorquage de votre véhicule sans franchise",
                    "- Mise à disposition d'un véhicule de remplacement",
                    "- Réseau national de dépannage 24/7"
                ],
                AssistancePop: [
                    "Dépannage/Remorquage 0km: Assistance 0km au véhicule en cas de panne, accident, vol ou tentative de vol, crevaison, perte ou vol des clés du véhicule, dans la limite de 200€ TTC et 50km.",
                    "Poursuite du voyage ou retour au domicile: Assistance au conducteur en cas de panne, accident, agression ou d’attente pour réparation à la suite d’un événement garantis. Titre de transport aller par bénéficiaire ou taxi. Dans la limite de 50 km autour du domicile ou lieu de villégiature.",
                    "Aide à la rédaction de constat amiable: 2 demandes par an.",
                    "Rapatriement du véhicule au domicile: 100€TTC dans la limite de 50km.",
                    "Véhicule de remplacement (Véhicule immobilisé plus de 24H) en cas de panne, accident: Remboursement de frais de véhicule de location. 25€TTC/Jour max 3 jours."
                ],
                AssistanceLegend: ["Plus jamais en rade"],
            },
        },

        VeloCargo: {
            Premium: {
                AssistanceContent: [
                    "- Remorquage de votre véhicule sans franchise",
                    "- Mise à disposition d'un véhicule de remplacement",
                    "- Réseau national de dépannage 24/7"
                ],
                AssistancePop: [
                    "Dépannage/Remorquage 0km: Assistance 0km au véhicule en cas de panne, accident, vol ou tentative de vol, crevaison, perte ou vol des clés du véhicule, dans la limite de 200€ TTC et 50km.",
                    "Poursuite du voyage ou retour au domicile: Assistance au conducteur en cas de panne, accident, agression ou d’attente pour réparation à la suite d’un événement garantis. Titre de transport aller par bénéficiaire ou taxi. Dans la limite de 50 km autour du domicile ou lieu de villégiature.",
                    "Aide à la rédaction de constat amiable: 2 demandes par an.",
                    "Vol de casque: Nous organisons et prenons en charge le taxi pour vous rendre sur le lieu de votre choix pour récupérer ou acheter un casque, ensuite, le taxi vous ramènera à l’endroit où vous avez dû laisser votre 2 roues. Taxi aller/retour dans la limite de 50km.",
                    "Vol ou perte des clés de l’antivol ou de la selle: Nous vous donnons les moyens de récupérer un double si vous le souhaitez (transport en taxi) ou, selon votre choix, nous vous adressons un dépanneur qui vient sectionner votre antivol ou ouvrir votre selle. Dépannage: 100€TTC, ou taxi: aller/retour dans la limite de 50km.",
                    "Soutien psychologique: Soutien psychologique d’un psychologue clinicien, par téléphone ou en cabinet, si vous êtes choqué après avoir été victime d’un accident ou du vol de votre 2 roues avec violence. 2 entretiens par an.",
                    "Rapatriement du véhicule au domicile: 100€TTC dans la limite de 50km.",
                    "Rapatriement du passager en cas d’accident ou de maladie: Titre de transport aller par bénéficiaire. En avion classe économique ou train.",
                    "Rapatriement du corps en cas de décès: Organisation et prise en charge du rapatriement de corps dans la limite de 300€.",
                    "Retrait immédiat de votre permis: Mise en sécurité du véhicule: vers le lieu le plus proche (garage, domicile ou siège de l’entreprise en cas de déplacement professionnel), organisation et prise en charge du retour à domicile (ou transport vers le lieu de destination) du conducteur et des passagers organisation et prise en charge du remorquage du véhicule dans la limite de 200€ TTC. Retour au domicile: Titre de transport aller par bénéficiaire. En avion classe économique ou train ou taxi dans la limite de 50km.",
                    "Chauffeur de remplacement en cas d’incapacité à la conduite pour raisons médicales du conducteur: 100€TTC.",
                    "Avance de la caution pénale et honoraires d'avocat à l'étranger: 300€ttc.",
                    "Envoi de pièces détachées suite à un accident ou une panne: Prise en charge des frais d’envoi dans la limite de 50€TTC.",
                    "SOS Fourrière: Organisation et prise en charge d’un taxi pour récupérer votre véhicule. Taxi aller dans la limite de 50km.",
                    "Véhicule de remplacement (Véhicule immobilisé plus de 24H) en cas de panne, accident: Remboursement de frais de véhicule de location. 25€TTC/Jour max 3 jours."
                ],
                AssistanceLegend: ["Plus jamais en rade"],

            },
            Standard: {
                AssistanceContent: [
                    "- Remorquage de votre véhicule sans franchise",
                    "- Mise à disposition d'un véhicule de remplacement",
                    "- Réseau national de dépannage 24/7"
                ],
                AssistancePop: [
                    "Dépannage/Remorquage 0km: Assistance 0km au véhicule en cas de panne, accident, vol ou tentative de vol, crevaison, perte ou vol des clés du véhicule, dans la limite de 200€ TTC et 50km.",
                    "Poursuite du voyage ou retour au domicile: Assistance au conducteur en cas de panne, accident, agression ou d’attente pour réparation à la suite d’un événement garantis. Titre de transport aller par bénéficiaire ou taxi. Dans la limite de 50 km autour du domicile ou lieu de villégiature.",
                    "Aide à la rédaction de constat amiable: 2 demandes par an.",
                    "Vol de casque: Nous organisons et prenons en charge le taxi pour vous rendre sur le lieu de votre choix pour récupérer ou acheter un casque, ensuite, le taxi vous ramènera à l’endroit où vous avez dû laisser votre 2 roues. Taxi aller/retour dans la limite de 50km.",
                    "Vol ou perte des clés de l’antivol ou de la selle: Nous vous donnons les moyens de récupérer un double si vous le souhaitez (transport en taxi) ou, selon votre choix, nous vous adressons un dépanneur qui vient sectionner votre antivol ou ouvrir votre selle. Dépannage: 100€TTC, ou taxi: aller/retour dans la limite de 50km.",
                    "Soutien psychologique: Soutien psychologique d’un psychologue clinicien, par téléphone ou en cabinet, si vous êtes choqué après avoir été victime d’un accident ou du vol de votre 2 roues avec violence. 2 entretiens par an.",
                    "Rapatriement du véhicule au domicile: 100€TTC dans la limite de 50km.",
                    "Rapatriement du passager en cas d’accident ou de maladie: Titre de transport aller par bénéficiaire. En avion classe économique ou train.",
                    "Rapatriement du corps en cas de décès: Organisation et prise en charge du rapatriement de corps dans la limite de 300€.",
                    "Retrait immédiat de votre permis: Mise en sécurité du véhicule: vers le lieu le plus proche (garage, domicile ou siège de l’entreprise en cas de déplacement professionnel), organisation et prise en charge du retour à domicile (ou transport vers le lieu de destination) du conducteur et des passagers organisation et prise en charge du remorquage du véhicule dans la limite de 200€ TTC. Retour au domicile: Titre de transport aller par bénéficiaire. En avion classe économique ou train ou taxi dans la limite de 50km.",
                    "Chauffeur de remplacement en cas d’incapacité à la conduite pour raisons médicales du conducteur: 100€TTC.",
                    "Avance de la caution pénale et honoraires d'avocat à l'étranger: 300€ttc.",
                    "Envoi de pièces détachées suite à un accident ou une panne: Prise en charge des frais d’envoi dans la limite de 50€TTC.",
                    "SOS Fourrière: Organisation et prise en charge d’un taxi pour récupérer votre véhicule. Taxi aller dans la limite de 50km.",
                    "Véhicule de remplacement (Véhicule immobilisé plus de 24H) en cas de panne, accident: Remboursement de frais de véhicule de location. 25€TTC/Jour max 3 jours."
                ],
                AssistanceLegend: ["Plus jamais en rade"],
            },
        },
        
        Trottinette: {
            Premium: {
                AssistanceContent: [
                    "- Remorquage de votre véhicule sans franchise",
                    "- Mise à disposition d'un véhicule de remplacement",
                    "- Réseau national de dépannage 24/7"
                ],
                AssistancePop: [
                    "Dépannage/Remorquage 0km: Assistance 0km au véhicule en cas de panne, accident, vol ou tentative de vol, crevaison, perte ou vol des clés du véhicule, dans la limite de 200€ TTC et 50km.",
                    "Poursuite du voyage ou retour au domicile: Assistance au conducteur en cas de panne, accident, agression ou d’attente pour réparation à la suite d’un événement garantis. Titre de transport aller par bénéficiaire ou taxi. Dans la limite de 50 km autour du domicile ou lieu de villégiature.",
                    "Aide à la rédaction de constat amiable: 2 demandes par an.",
                    "Rapatriement du véhicule au domicile: 100€TTC dans la limite de 50km.",
                    "Véhicule de remplacement (Véhicule immobilisé plus de 24H) en cas de panne, accident: Remboursement de frais de véhicule de location. 25€TTC/Jour max 3 jours."
                ],
                AssistanceLegend: ["Plus jamais en rade"],

            },
            Standard: {
                AssistanceContent: [
                    "- Remorquage de votre véhicule sans franchise",
                    "- Mise à disposition d'un véhicule de remplacement",
                    "- Réseau national de dépannage 24/7"
                ],
                AssistancePop: [
                    "Dépannage/Remorquage 0km: Assistance 0km au véhicule en cas de panne, accident, vol ou tentative de vol, crevaison, perte ou vol des clés du véhicule, dans la limite de 200€ TTC et 50km.",
                    "Poursuite du voyage ou retour au domicile: Assistance au conducteur en cas de panne, accident, agression ou d’attente pour réparation à la suite d’un événement garantis. Titre de transport aller par bénéficiaire ou taxi. Dans la limite de 50 km autour du domicile ou lieu de villégiature.",
                    "Aide à la rédaction de constat amiable: 2 demandes par an.",
                    "Rapatriement du véhicule au domicile: 100€TTC dans la limite de 50km.",
                    "Véhicule de remplacement (Véhicule immobilisé plus de 24H) en cas de panne, accident: Remboursement de frais de véhicule de location. 25€TTC/Jour max 3 jours."
                ],
                AssistanceLegend: ["Plus jamais en rade"],
            },
        },
        
        Moto: {
            Premium: {
                AssistanceContent: [
                    "- Remorquage de votre véhicule sans franchise",
                    "- Mise à disposition d'un véhicule de remplacement",
                    "- Réseau national de dépannage 24/7"
                ],
                AssistancePop: [
                    "Dépannage/Remorquage 0km: Assistance 0km au véhicule en cas de panne, accident, vol ou tentative de vol, crevaison, perte ou vol des clés du véhicule, dans la limite de 200€ TTC et 50km.",
                    "Poursuite du voyage ou retour au domicile: Assistance au conducteur en cas de panne, accident, agression ou d’attente pour réparation à la suite d’un événement garantis. Titre de transport aller par bénéficiaire ou taxi. Dans la limite de 50 km autour du domicile ou lieu de villégiature.",
                    "Aide à la rédaction de constat amiable: 2 demandes par an.",
                    "Vol de casque: Nous organisons et prenons en charge le taxi pour vous rendre sur le lieu de votre choix pour récupérer ou acheter un casque, ensuite, le taxi vous ramènera à l’endroit où vous avez dû laisser votre 2 roues. Taxi aller/retour dans la limite de 50km.",
                    "Vol ou perte des clés de l’antivol ou de la selle: Nous vous donnons les moyens de récupérer un double si vous le souhaitez (transport en taxi) ou, selon votre choix, nous vous adressons un dépanneur qui vient sectionner votre antivol ou ouvrir votre selle. Dépannage: 100€TTC, ou taxi: aller/retour dans la limite de 50km.",
                    "Soutien psychologique: Soutien psychologique d’un psychologue clinicien, par téléphone ou en cabinet, si vous êtes choqué après avoir été victime d’un accident ou du vol de votre 2 roues avec violence. 2 entretiens par an.",
                    "Rapatriement du véhicule au domicile: 100€TTC dans la limite de 50km.",
                    "Rapatriement du passager en cas d’accident ou de maladie: Titre de transport aller par bénéficiaire. En avion classe économique ou train.",
                    "Rapatriement du corps en cas de décès: Organisation et prise en charge du rapatriement de corps dans la limite de 300€.",
                    "Retrait immédiat de votre permis: Mise en sécurité du véhicule: vers le lieu le plus proche (garage, domicile ou siège de l’entreprise en cas de déplacement professionnel), organisation et prise en charge du retour à domicile (ou transport vers le lieu de destination) du conducteur et des passagers organisation et prise en charge du remorquage du véhicule dans la limite de 200€ TTC. Retour au domicile: Titre de transport aller par bénéficiaire. En avion classe économique ou train ou taxi dans la limite de 50km.",
                    "Chauffeur de remplacement en cas d’incapacité à la conduite pour raisons médicales du conducteur: 100€TTC.",
                    "Avance de la caution pénale et honoraires d'avocat à l'étranger: 300€ttc.",
                    "Envoi de pièces détachées suite à un accident ou une panne: Prise en charge des frais d’envoi dans la limite de 50€TTC.",
                    "SOS Fourrière: Organisation et prise en charge d’un taxi pour récupérer votre véhicule. Taxi aller dans la limite de 50km.",
                    "Véhicule de remplacement (Véhicule immobilisé plus de 24H) en cas de panne, accident: Remboursement de frais de véhicule de location. 25€TTC/Jour max 3 jours."
                ],
                AssistanceLegend: ["Plus jamais en rade"],

            },
            Standard: {
                AssistanceContent: [
                    "- Remorquage de votre véhicule sans franchise",
                    "- Mise à disposition d'un véhicule de remplacement",
                    "- Réseau national de dépannage 24/7"
                ],
                AssistancePop: [
                    "Dépannage/Remorquage 0km: Assistance 0km au véhicule en cas de panne, accident, vol ou tentative de vol, crevaison, perte ou vol des clés du véhicule, dans la limite de 200€ TTC et 50km.",
                    "Poursuite du voyage ou retour au domicile: Assistance au conducteur en cas de panne, accident, agression ou d’attente pour réparation à la suite d’un événement garantis. Titre de transport aller par bénéficiaire ou taxi. Dans la limite de 50 km autour du domicile ou lieu de villégiature.",
                    "Aide à la rédaction de constat amiable: 2 demandes par an.",
                    "Vol de casque: Nous organisons et prenons en charge le taxi pour vous rendre sur le lieu de votre choix pour récupérer ou acheter un casque, ensuite, le taxi vous ramènera à l’endroit où vous avez dû laisser votre 2 roues. Taxi aller/retour dans la limite de 50km.",
                    "Vol ou perte des clés de l’antivol ou de la selle: Nous vous donnons les moyens de récupérer un double si vous le souhaitez (transport en taxi) ou, selon votre choix, nous vous adressons un dépanneur qui vient sectionner votre antivol ou ouvrir votre selle. Dépannage: 100€TTC, ou taxi: aller/retour dans la limite de 50km.",
                    "Soutien psychologique: Soutien psychologique d’un psychologue clinicien, par téléphone ou en cabinet, si vous êtes choqué après avoir été victime d’un accident ou du vol de votre 2 roues avec violence. 2 entretiens par an.",
                    "Rapatriement du véhicule au domicile: 100€TTC dans la limite de 50km.",
                    "Rapatriement du passager en cas d’accident ou de maladie: Titre de transport aller par bénéficiaire. En avion classe économique ou train.",
                    "Rapatriement du corps en cas de décès: Organisation et prise en charge du rapatriement de corps dans la limite de 300€.",
                    "Retrait immédiat de votre permis: Mise en sécurité du véhicule: vers le lieu le plus proche (garage, domicile ou siège de l’entreprise en cas de déplacement professionnel), organisation et prise en charge du retour à domicile (ou transport vers le lieu de destination) du conducteur et des passagers organisation et prise en charge du remorquage du véhicule dans la limite de 200€ TTC. Retour au domicile: Titre de transport aller par bénéficiaire. En avion classe économique ou train ou taxi dans la limite de 50km.",
                    "Chauffeur de remplacement en cas d’incapacité à la conduite pour raisons médicales du conducteur: 100€TTC.",
                    "Avance de la caution pénale et honoraires d'avocat à l'étranger: 300€ttc.",
                    "Envoi de pièces détachées suite à un accident ou une panne: Prise en charge des frais d’envoi dans la limite de 50€TTC.",
                    "SOS Fourrière: Organisation et prise en charge d’un taxi pour récupérer votre véhicule. Taxi aller dans la limite de 50km.",
                    "Véhicule de remplacement (Véhicule immobilisé plus de 24H) en cas de panne, accident: Remboursement de frais de véhicule de location. 25€TTC/Jour max 3 jours."
                ],
                AssistanceLegend: ["Plus jamais en rade"],
            },
        },

        Scooter: {
            Premium: {
                AssistanceContent: [
                    "- Remorquage de votre véhicule sans franchise",
                    "- Mise à disposition d'un véhicule de remplacement",
                    "- Réseau national de dépannage 24/7"
                ],
                AssistancePop: [
                    "Dépannage/Remorquage 0km: Assistance 0km au véhicule en cas de panne, accident, vol ou tentative de vol, crevaison, perte ou vol des clés du véhicule, dans la limite de 200€ TTC et 50km.",
                    "Poursuite du voyage ou retour au domicile: Assistance au conducteur en cas de panne, accident, agression ou d’attente pour réparation à la suite d’un événement garantis. Titre de transport aller par bénéficiaire ou taxi. Dans la limite de 50 km autour du domicile ou lieu de villégiature.",
                    "Aide à la rédaction de constat amiable: 2 demandes par an.",
                    "Vol de casque: Nous organisons et prenons en charge le taxi pour vous rendre sur le lieu de votre choix pour récupérer ou acheter un casque, ensuite, le taxi vous ramènera à l’endroit où vous avez dû laisser votre 2 roues. Taxi aller/retour dans la limite de 50km.",
                    "Vol ou perte des clés de l’antivol ou de la selle: Nous vous donnons les moyens de récupérer un double si vous le souhaitez (transport en taxi) ou, selon votre choix, nous vous adressons un dépanneur qui vient sectionner votre antivol ou ouvrir votre selle. Dépannage: 100€TTC, ou taxi: aller/retour dans la limite de 50km.",
                    "Soutien psychologique: Soutien psychologique d’un psychologue clinicien, par téléphone ou en cabinet, si vous êtes choqué après avoir été victime d’un accident ou du vol de votre 2 roues avec violence. 2 entretiens par an.",
                    "Rapatriement du véhicule au domicile: 100€TTC dans la limite de 50km.",
                    "Rapatriement du passager en cas d’accident ou de maladie: Titre de transport aller par bénéficiaire. En avion classe économique ou train.",
                    "Rapatriement du corps en cas de décès: Organisation et prise en charge du rapatriement de corps dans la limite de 300€.",
                    "Retrait immédiat de votre permis: Mise en sécurité du véhicule: vers le lieu le plus proche (garage, domicile ou siège de l’entreprise en cas de déplacement professionnel), organisation et prise en charge du retour à domicile (ou transport vers le lieu de destination) du conducteur et des passagers organisation et prise en charge du remorquage du véhicule dans la limite de 200€ TTC. Retour au domicile: Titre de transport aller par bénéficiaire. En avion classe économique ou train ou taxi dans la limite de 50km.",
                    "Chauffeur de remplacement en cas d’incapacité à la conduite pour raisons médicales du conducteur: 100€TTC.",
                    "Avance de la caution pénale et honoraires d'avocat à l'étranger: 300€ttc.",
                    "Envoi de pièces détachées suite à un accident ou une panne: Prise en charge des frais d’envoi dans la limite de 50€TTC.",
                    "SOS Fourrière: Organisation et prise en charge d’un taxi pour récupérer votre véhicule. Taxi aller dans la limite de 50km.",
                    "Véhicule de remplacement (Véhicule immobilisé plus de 24H) en cas de panne, accident: Remboursement de frais de véhicule de location. 25€TTC/Jour max 3 jours."
                ],
                AssistanceLegend: ["Plus jamais en rade"],

            },
            Standard: {
                AssistanceContent: [
                    "- Remorquage de votre véhicule sans franchise",
                    "- Mise à disposition d'un véhicule de remplacement",
                    "- Réseau national de dépannage 24/7"
                ],
                AssistancePop: [
                    "Dépannage/Remorquage 0km: Assistance 0km au véhicule en cas de panne, accident, vol ou tentative de vol, crevaison, perte ou vol des clés du véhicule, dans la limite de 200€ TTC et 50km.",
                    "Poursuite du voyage ou retour au domicile: Assistance au conducteur en cas de panne, accident, agression ou d’attente pour réparation à la suite d’un événement garantis. Titre de transport aller par bénéficiaire ou taxi. Dans la limite de 50 km autour du domicile ou lieu de villégiature.",
                    "Aide à la rédaction de constat amiable: 2 demandes par an.",
                    "Vol de casque: Nous organisons et prenons en charge le taxi pour vous rendre sur le lieu de votre choix pour récupérer ou acheter un casque, ensuite, le taxi vous ramènera à l’endroit où vous avez dû laisser votre 2 roues. Taxi aller/retour dans la limite de 50km.",
                    "Vol ou perte des clés de l’antivol ou de la selle: Nous vous donnons les moyens de récupérer un double si vous le souhaitez (transport en taxi) ou, selon votre choix, nous vous adressons un dépanneur qui vient sectionner votre antivol ou ouvrir votre selle. Dépannage: 100€TTC, ou taxi: aller/retour dans la limite de 50km.",
                    "Soutien psychologique: Soutien psychologique d’un psychologue clinicien, par téléphone ou en cabinet, si vous êtes choqué après avoir été victime d’un accident ou du vol de votre 2 roues avec violence. 2 entretiens par an.",
                    "Rapatriement du véhicule au domicile: 100€TTC dans la limite de 50km.",
                    "Rapatriement du passager en cas d’accident ou de maladie: Titre de transport aller par bénéficiaire. En avion classe économique ou train.",
                    "Rapatriement du corps en cas de décès: Organisation et prise en charge du rapatriement de corps dans la limite de 300€.",
                    "Retrait immédiat de votre permis: Mise en sécurité du véhicule: vers le lieu le plus proche (garage, domicile ou siège de l’entreprise en cas de déplacement professionnel), organisation et prise en charge du retour à domicile (ou transport vers le lieu de destination) du conducteur et des passagers organisation et prise en charge du remorquage du véhicule dans la limite de 200€ TTC. Retour au domicile: Titre de transport aller par bénéficiaire. En avion classe économique ou train ou taxi dans la limite de 50km.",
                    "Chauffeur de remplacement en cas d’incapacité à la conduite pour raisons médicales du conducteur: 100€TTC.",
                    "Avance de la caution pénale et honoraires d'avocat à l'étranger: 300€ttc.",
                    "Envoi de pièces détachées suite à un accident ou une panne: Prise en charge des frais d’envoi dans la limite de 50€TTC.",
                    "SOS Fourrière: Organisation et prise en charge d’un taxi pour récupérer votre véhicule. Taxi aller dans la limite de 50km.",
                    "Véhicule de remplacement (Véhicule immobilisé plus de 24H) en cas de panne, accident: Remboursement de frais de véhicule de location. 25€TTC/Jour max 3 jours."
                ],
                AssistanceLegend: ["Plus jamais en rade"],
            },
        },
    },
};

export default function DataService(price, vehicle, servicesOption, servicesData) {
    let adjustedPrice = price;
  
    if (vehicle === 'Velo') {
      if (price <= 1500) {
        adjustedPrice = '1500';
      } else if (price <= 2500) {
        adjustedPrice = '2500';
      } else if (price <= 7000) {
        adjustedPrice = '7000';
      } else {
        adjustedPrice = '15000';
      }
    } else if (vehicle === 'Trottinette') {
        if (price <= 1500) {
            adjustedPrice = '1500';
        } else if (price <= 3000) {
            adjustedPrice = '3000';
        } else {
            adjustedPrice = '10000';
        }
    } else {
        if (price <= 7000) {
            adjustedPrice = '7000';
        } else if (price <= 15000) {
            adjustedPrice = '15000';
        } else if (price <= 30000) {
            adjustedPrice = '30000';
        }
    }
    
    const option = {
        Maintenance: 'Standard',
        Assistance: 'Standard',
        Warranty: 'Standard',
        Insurance: 'Standard',
    };
  
    servicesOption.forEach((service) => {
        if (service.serviceName === 'Maintenance') {
            if (service.selectedOption === 1){
                option.Maintenance = 'Premium';
            }else{
                option.Maintenance = 'Standard';
            }
        } else if (service.serviceName === 'Assistance' ) {
            if (service.selectedOption === 1){
                option.Assistance = 'Premium';
            }else{
                option.Assistance = 'Standard';
            }
        } else if (service.serviceName === 'Warranty' ) {
            if (service.selectedOption === 1){
                option.Warranty = 'Premium';
            }else{
                option.Warranty = 'Standard';
            }
        } else if (service.serviceName === 'Insurance') {
            if (service.selectedOption === 1){
                option.Insurance = 'Premium';
            }else{
                option.Insurance = 'Standard';
            }
        }
    });
  
    const newServicesData = [...servicesData];
  
    ['Warranty', 'Insurance', 'Maintenance', 'Assistance'].forEach((serviceName) => {
      const serviceData = DATA_MAP[serviceName]?.[vehicle]?.[option[serviceName]];
      const adjustedServiceData = serviceData?.[adjustedPrice] ?? serviceData;
  
      if (adjustedServiceData) {
        if (Array.isArray(adjustedServiceData[`${serviceName}Content`]) &&
            Array.isArray(adjustedServiceData[`${serviceName}Pop`]) &&
            Array.isArray(adjustedServiceData[`${serviceName}Legend`])) {
  
          const serviceIndex = newServicesData.findIndex(service => service.serviceName === serviceName);
          if (serviceIndex !== -1) {
            newServicesData[serviceIndex] = {
              ...newServicesData[serviceIndex],
              serviceContent: adjustedServiceData[`${serviceName}Content`],
              servicePop: adjustedServiceData[`${serviceName}Pop`],
              serviceLegend: adjustedServiceData[`${serviceName}Legend`],
            };
          }
        } else {
          console.warn(`Les données de ${serviceName} ne sont pas au format attendu.`);
        }
      } else {
        console.warn(`Aucune donnée de ${serviceName} correspondante trouvée pour ces paramètres.`);
      }
    });
  
    return newServicesData;
  }